import productDetailsEffect from './product-details';
import getStore from './get-store';
import notifyMenuFailureEffects from './notify-menu-failure';
import geolocate from './geolocate';
import invalidateMostRecentOrder from './invalidate-most-recent-order';
import updateCartAvailabilityEffects from './availability';
import fetchPricingEffects from './cart';
import favoritesEffects from './favorite-products';
import previousOrdersEffects from './previous-orders';

export default [
  ...productDetailsEffect,
  ...getStore,
  ...notifyMenuFailureEffects,
  ...geolocate,
  ...invalidateMostRecentOrder,
  ...favoritesEffects,
  ...previousOrdersEffects,
  ...updateCartAvailabilityEffects,
  ...fetchPricingEffects,
];
