import {
  ADD_PAYMENT,
  REMOVE_PAYMENT_INSTRUMENT,
  SET_NEW_PAYMENT_INSTRUMENT,
} from 'shared/app/bundles/wallet';

import { SET_SELECTED_PAYMENT } from 'shared/app/state/action-creators/types';

export default (state = null, action) => {
  switch (action.type) {
    case SET_NEW_PAYMENT_INSTRUMENT:
      if (state?.paymentInstrumentId === ADD_PAYMENT) {
        return action.payload;
      }
      return state;
    case REMOVE_PAYMENT_INSTRUMENT:
      return null;
    case SET_SELECTED_PAYMENT:
      return action.payload;
    default: {
      return state;
    }
  }
};
