/* eslint-disable max-params */

import { createSelector } from 'reselect';
import ms from 'milliseconds';

import {
  signedInSelector,
  hasGuestSessionSelector,
} from 'shared/app/bundles/user';
import { appTimeSelector } from 'shared/app/shell';

import shouldUpdate from 'shared/app/stale-reducers/should-update';

const orderingSelector = (state) => state.ordering;

export const pricingRulesSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.pricingRules
);

const pricingRulesCacheTime = ms.minutes(5);

export const shouldFetchPricingRulesSelector = createSelector(
  signedInSelector,
  hasGuestSessionSelector,
  pricingRulesSelector,
  appTimeSelector,
  (signedIn, hasGuestSession, pricingRules, appTime) => {
    if (!signedIn && !hasGuestSession) return false;

    const updateNeeded = shouldUpdate(pricingRules, {
      staleTime: pricingRulesCacheTime,
      now: appTime,
    });

    return updateNeeded;
  }
);
