import { createSelector } from 'reselect';

import { routeSelector } from 'shared/app/state/selectors/routes';

import { validPaymentInstrumentsSelector } from 'shared/app/bundles/wallet';

const configSelector = (state) => state.config;
const uiSelector = (state) => state.accountPaymentMethod.ui;

export const editingPaymentMethodIdSelector = createSelector(
  uiSelector,
  (ui) => ui.editingPaymentMethodId
);

export const editingPaymentMethodIdHashSelector = createSelector(
  uiSelector,
  (ui) => ui.editingPaymentMethodIdHash
);

export const editingPaymentInstrumentSelector = createSelector(
  editingPaymentMethodIdSelector,
  editingPaymentMethodIdHashSelector,
  validPaymentInstrumentsSelector,
  (
    editingPaymentMethodId,
    editingPaymentMethodIdHash,
    paymentInstrumentsData
  ) => {
    return paymentInstrumentsData.find((paymentInstrument) => {
      return (
        paymentInstrument.paymentMethodId === editingPaymentMethodId &&
        paymentInstrument.paymentMethodIdHash === editingPaymentMethodIdHash
      );
    });
  }
);

export const editingPaymentInstrumentIdSelector = createSelector(
  uiSelector,
  validPaymentInstrumentsSelector,
  (ui, paymentInstrumentsData) => {
    const foundPaymentInstrument = paymentInstrumentsData.find(
      (paymentInstrument) => {
        return paymentInstrument.paymentMethodId === ui.editingPaymentMethodId;
      }
    );
    return foundPaymentInstrument
      ? foundPaymentInstrument.paymentInstrumentId
      : null;
  }
);

export const expectPaymentTokenResponseSelector = createSelector(
  uiSelector,
  (ui) => ui.expectPaymentTokenResponse
);

export const isEditCCRouteActiveSelector = createSelector(
  routeSelector,
  (route) => {
    return route === '/account/payment-method/edit/credit-card';
  }
);

export const newPaymentOptionEnabledSelector = createSelector(
  configSelector,
  (config) => {
    return (
      config?.universal?.newPaymentOptionEnabled ?? {
        creditCard: true,
        giftCard: true,
        paypal: true,
        venmo: false,
        campusCard: false,
      }
    );
  }
);

export const spfModeSelector = createSelector(routeSelector, (route) => {
  if (route === '/account/payment-method/edit/credit-card') {
    return 'edit';
  }
  if (route === '/account/payment-method/add/credit-card') {
    return 'add';
  }
  return 'guestV1';
});
