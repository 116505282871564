import React, { useState } from 'react';
import ViewportChecker from '@starbucks-web/pattern-library/lib/components/viewport-checker';
import { trackStreamCardView } from '../../state/track-event';

const StreamItem = ({ addNotification, idx, item, component: Component }) => {
  const [didTrackEvent, setDidTrackEvent] = useState(false);
  return (
    <ViewportChecker percentVisible={50}>
      {({ isIntersecting, elementRef }) => {
        if (isIntersecting && !didTrackEvent) {
          /* eslint-disable camelcase */
          trackStreamCardView({
            authentication_state: item?.authState ? 'true' : 'false',
            stream_card_id: item?.itemId,
            stream_card_type: item?.type,
            stream_card_position: idx.toString(),
          });
          /* eslint-enable camelcase */
          setDidTrackEvent(true);
        }

        return (
          <div ref={elementRef}>
            <Component
              {...item}
              addNotification={addNotification}
              idx={idx}
              key={`streamItemType-${idx}`}
            />
          </div>
        );
      }}
    </ViewportChecker>
  );
};

export default StreamItem;
