import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Frap from 'shared/app/components/frap-with-messages';

import FrapContainer from 'shared/app/components/frap-container';
import { configImagesUrlSelector } from 'shared/app/state/selectors/config';

import { storeNameSelector } from '../state/selectors';
import messages from './messages';

const PickupForm = ({
  configImagesUrl,
  imageName,
  pickupMessage,
  loading,
  onSubmit,
  storeName,
  children,
}) => {
  return (
    <div className="flex flex-column items-center">
      <img
        alt=""
        className="mt5 mb2"
        src={`${configImagesUrl}/pickup/${imageName}`}
      />
      {storeName && (
        <div className="color-textBlackSoft" style={{ fontSize: '12px' }}>
          <FormattedMessage
            {...messages.store}
            values={{
              storeName,
            }}
          />
        </div>
      )}

      <p className="mb4 pt5 px2 text-center" style={{ fontSize: '22px' }}>
        <FormattedMessage {...pickupMessage} />
      </p>

      {children}

      <FrapContainer>
        <Frap
          className="self-end"
          loading={loading}
          onClick={onSubmit}
          type="submit"
        >
          <FormattedMessage {...messages.checkInCTA} />
        </Frap>
      </FrapContainer>
    </div>
  );
};

const select = (state) => ({
  storeName: storeNameSelector(state),
  configImagesUrl: configImagesUrlSelector(state),
});

export default connect(select)(PickupForm);
