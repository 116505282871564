import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import Frap from 'shared/app/components/frap-with-messages';

import { trackMOPStoreSelect } from '../../state/actions/track-event';

import messages from './messages';

const ConfirmStoreFrap = ({ onClick, location, isFrap = true }) => {
  if (!location) {
    return null;
  }

  const { formatMessage } = useIntl();

  const frapLabel = () => {
    return formatMessage(messages.orderHere);
  };

  const buttonAriaLabel = formatMessage(messages.confirmStoreDetails, {
    storeName: location.store.name,
  });

  const onClickHandler = (ev) => {
    trackMOPStoreSelect({
      // eslint-disable-next-line camelcase
      store_id: location.store.id,
    });
    onClick(ev);
  };

  return isFrap ? (
    <Frap
      aria-label={buttonAriaLabel}
      data-e2e="confirmStoreButton"
      onClick={onClickHandler}
    >
      {frapLabel()}
    </Frap>
  ) : (
    <Button
      aria-label={buttonAriaLabel}
      className="relative"
      data-e2e="confirmStoreButton"
      onClick={onClickHandler}
      style={{
        minHeight: 'auto',
        minWidth: 'auto',
      }}
      visualStyle="positive"
    >
      {frapLabel()}
    </Button>
  );
};

ConfirmStoreFrap.propTypes = {
  inMenuStoreLocator: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default ConfirmStoreFrap;
