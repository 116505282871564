import { createSelector, createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { PseudoHttpError } from 'shared/universal/custom-errors';
import getThumbnailUri from 'shared/app/utils/get-thumbnail-uri';
import {
  allCategoriesSelector,
  fullMenuSelector,
  fullMenuIsEmptySelector,
  menuFetchFailedSelector,
} from 'shared/app/bundles/menu';

import getFormId from 'shared/app/bundles/menu/util/get-menu-product-form-id';

import {
  currentCategorySelector,
  currentSubcategoriesSelector,
  currentSubcategorySelector,
} from './menu';

import { routeDerivedDataSelector } from './router';

export const TILE_TYPES = {
  product: 'product',
  menu: 'menu',
};

import messages from './messages';

const productTiles = ({ products }) => {
  return products?.map((product) => {
    const { productNumber, formCode } = product;
    return {
      tileType: TILE_TYPES.product,
      id: getFormId({ productNumber, formCode }),
      imgUri: getThumbnailUri(product) || '',
      text: product.name,
      href: product.uri,
      displayOrder: product.displayOrder,
      product,
    };
  });
};

/*
 * Provides content in the form of formatted product 'tiles' (product name/thumbnail)
 * for category routes, eg. /menu/drinks/hot-coffees, and for subcategory routes, eg.
 * /menu/drinks/hot-coffees/americanos. We don't have any links within the app to
 * subcategory routes, but links can be created to them by marketing content.
 */
const categoryPageContent = ({
  routeDerivedData,
  category,
  subcategories,
  subcategory,
}) => {
  if (isEmpty(category)) {
    return {
      error: true,
      payload: new PseudoHttpError({
        code: 404,
        message: `Category ${routeDerivedData.category} does not exist.`,
      }),
      meta: {
        statusMessage: messages.emptyCategory,
      },
    };
  }

  // This category has its own products and no subcategories
  if (category?.products?.length) {
    return {
      active: null,
      sections: [
        {
          header: '',
          displayOrder: 1,
          tiles: productTiles({
            products: category.products,
          }),
        },
      ],
    };
  }

  if (isEmpty(subcategories)) {
    return {
      error: true,
      payload: new PseudoHttpError({
        code: 404,
        message: `Category ${routeDerivedData.category} has neither subcategories nor products.`,
      }),
      meta: {
        statusMessage: messages.emptyCategory,
      },
    };
  }

  if (isEmpty(subcategory) && routeDerivedData.subcategory) {
    return {
      error: true,
      payload: new PseudoHttpError({
        code: 404,
        message: `Category ${routeDerivedData.subcategory} does not exist.`,
      }),
      meta: {
        statusMessage: messages.emptyCategory,
      },
    };
  }

  // If this is a subcategory route, we loop through and
  // show only the subcategory title and it's child products.
  // Else this is a category route, we loop through and show the
  // category's subcategory titles and products.
  const activeSubcategories = subcategory ? [subcategory] : subcategories;

  return {
    active: subcategory?.id,
    sections: activeSubcategories.map((subcat) => ({
      header: subcat.name,
      headerAnchor: subcat.id,
      href: subcat.uri,
      displayOrder: subcat.displayOrder,
      tiles: productTiles({
        products: subcat.products,
      }),
    })),
  };
};

/*
 * Provides content for the root menu page, ie. /menu. This is a list of the top level menus,
 * eg. "Drinks", "Food", and their children ("Hot Coffees", "Breakfast Sandwiches") represented as category 'tiles', ie. (category name/thumbnail).
 * There is no support for rendering a top level menu as a standalone route, eg. /menu/drinks;
 * those routes will get redirected to the root menu.
 */
const rootMenuContent = ({ fullMenu }) => ({
  active: null,
  sections: fullMenu.map((menu) => {
    return {
      header: menu.name,
      headerAnchor: menu.id,
      href: menu.uri,
      displayOrder: menu.displayOrder,
      tiles: menu.children.map((category) => {
        return {
          tileType: TILE_TYPES.menu,
          id: category.id,
          // use Orchestra supplied category image
          // fallback to first image from first lowest level product as thumb
          imgUri: getThumbnailUri(
            category?.categoryImageURL
              ? category
              : category?.children?.[0]?.products?.[0] ||
                  category?.products?.[0]
          ),
          text: category.name,
          href: category.uri,
          displayOrder: category.displayOrder,
        };
      }),
    };
  }),
});

// What is createStructuredSelector? I found this note helpful:
// https://github.com/reduxjs/reselect/issues/178#issuecomment-252764406
const contentArgsSelector = createStructuredSelector({
  routeDerivedData: routeDerivedDataSelector,
  fullMenu: fullMenuSelector,
  fullMenuIsEmpty: fullMenuIsEmptySelector,
  menuFetchFailed: menuFetchFailedSelector,
  categories: allCategoriesSelector,
  category: currentCategorySelector,
  subcategories: currentSubcategoriesSelector,
  subcategory: currentSubcategorySelector,
});

export const contentSelector = createSelector(contentArgsSelector, (args) => {
  // this selector is not used for the product detail page
  if (args.routeDerivedData.productNumber || args.routeDerivedData.formCode) {
    return null;
  }

  if (args.fullMenuIsEmpty) {
    return {
      error: true,
      payload: new PseudoHttpError({
        code: 404,
        message: 'The menu request returned an empty payload.',
      }),
      meta: {
        statusMessage: messages.emptyMenus,
      },
    };
  }

  if (args.menuFetchFailed) {
    return {
      error: true,
      payload: new PseudoHttpError({
        code: 500,
        message: 'The menu request returned a server error.',
      }),
      meta: {
        statusMessage: messages.errorMenus,
      },
    };
  }

  return args.routeDerivedData.category
    ? categoryPageContent(args)
    : rootMenuContent(args);
});
