import createSingleResourceReducer from 'shared/app/stale-reducers/single-resource-reducer';

import { FETCH_PRICING_BY_STORE } from '../actions/types';

const { initialState, reducer } = createSingleResourceReducer({
  startAction: FETCH_PRICING_BY_STORE,
});

export default (state = initialState, action) => {
  state = reducer(state, action);

  switch (action.type) {
    case FETCH_PRICING_BY_STORE:
      const { storeNumber } = action.payload;
      return {
        ...state,
        storeNumber,
      };
  }
  return state;
};
