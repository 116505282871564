import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading/';

import DialogWithMessages from 'shared/app/components/dialog-with-messages';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

import { removeCard } from '../../state/actions/cards-management-remove-card';
import messages from './messages';

export const REMOVE_CARD_ZERO_BALANCE_ADD_DIALOG_ID =
  'RemoveCardOnZeroBalanceAddDialogId';

const RemoveCardOnZeroBalanceAddDialog = ({ cardId }) => {
  const dispatch = useDispatch();
  const { formatMessage, formatNumber, formats } = useIntl();

  return (
    <DialogWithMessages
      cancelText={formatMessage(sharedCallsToAction.remove)}
      confirmButtonProps={{ 'data-e2e': 'removeCardConfirmButton' }}
      confirmText={formatMessage(sharedCallsToAction.keep)}
      onCancel={() => dispatch(removeCard(cardId))}
    >
      <Heading
        data-e2e="remove-card-on-zero-balance-add-heading"
        id={REMOVE_CARD_ZERO_BALANCE_ADD_DIALOG_ID}
        size="lg"
        tagName="h2"
      >
        {formatMessage(messages.removeCardOnZeroBalanceAddHeading)}
      </Heading>
      <p className="pt3" data-e2e="remove-card-on-zero-balance-add-detail">
        {formatMessage(messages.removeCardOnZeroBalanceAddDetail, {
          balance: formatNumber(0, formats.number.money),
        })}
      </p>
    </DialogWithMessages>
  );
};

export default RemoveCardOnZeroBalanceAddDialog;
