/*
This module should only export selector functions that expect to
take the full state object as an argument.
*/
/* eslint-disable max-statements, max-params */
import { createSelector } from 'reselect';
import qs from 'query-string';
import { includes, isEqual, round } from 'lodash';
import { LOCATION_CHANGE } from 'redux-first-history';

import { getDistance } from 'shared/app/utils/map-utils';
import { buildStoreUrl } from '../../../universal/utils/expanded-store-url';

// our other selectors
import mapCoordsSelector from './target-map-coords';
import { signedInSelector } from 'shared/app/bundles/user';
import { preventFocusTargetsSelector } from 'shared/app/shell/state/selectors/app-ui';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import {
  currentRouteSelector,
  previousRouteSelector,
} from 'shared/app/state/selectors/routes';

const previousActionSelector = (state) => state.previousAction;
const storeLocatorSelector = (state) => state.storeLocator;
export const targetMapCoordsSelector = mapCoordsSelector;

import {
  fetchAtLocationSelector,
  hasLocationsSelector,
  locationQuerySelector,
  locationQueryUrlSelector,
  locationsLoadingSelector,
  locationStateSelector,
  panThresholdSelector,
  placeNameSelector,
  showNoStoresCardSelector,
  hasNotFoundErrorSelector,
  userGeolocationSelector,
  placeNameNotFoundSelector,
} from './location';
import {
  initialUrlZoomOutsideThresholdSelector,
  mapStateSelector,
  userOverrideSelector,
} from './map';
import { configSelector, inMenuStoreLocatorSelector } from './config';
import { selectedFeaturesStateSelector } from './selected-features';
import {
  expandedStoreStateSelector,
  expandedStoreActiveSelector,
  expandedStoreNumberSelector,
} from './expanded-store';
import { isFilterOverlayOpenSelector } from './filter';
import {
  geolocationFailedSelector,
  geolocationIsLoadingSelector,
} from 'shared/app/bundles/geolocation';

export const httpStatusSelector = createSelector(
  locationStateSelector,
  expandedStoreNumberSelector,
  (locationState, expandedStoreNumber) =>
    expandedStoreNumber && locationState.error ? 404 : 200
);

export const pathnameSelector = createSelector(
  expandedStoreStateSelector,
  currentRouteSelector,
  (expandedStoreState, currentRoute) => {
    if (expandedStoreState?.expanded) {
      return buildStoreUrl(expandedStoreState);
    }
    if (currentRoute?.pathname === '/store-locator/delivery') {
      return currentRoute.pathname;
    }
    return '/store-locator';
  }
);

export const isViewingStoreLocatorSelector = createSelector(
  currentRouteSelector,
  (currentRoute) =>
    currentRoute.route && currentRoute.route.indexOf('/store-locator') !== -1
);

export const distanceMovedSelector = createSelector(
  locationQuerySelector,
  targetMapCoordsSelector,
  (locationQuery, targetCoords) => {
    if (!locationQuery) {
      return 0;
    }
    const coordinates = {
      lat: locationQuery.lat,
      lng: locationQuery.lng,
    };
    return getDistance(coordinates, targetCoords);
  }
);

export const outsidePanThresholdSelector = createSelector(
  distanceMovedSelector,
  panThresholdSelector,
  mapStateSelector,
  (distanceMoved, panThreshold, mapState) => {
    // Return true if user panned the map beyond the threshold.
    return mapState.userOverride && distanceMoved > panThreshold;
  }
);

export const currentFetchCoordsSelector = createSelector(
  mapStateSelector,
  locationStateSelector,
  outsidePanThresholdSelector,
  userGeolocationSelector,
  (mapState, locationState, outsidePanThreshold, userGeolocation) => {
    const lastLocationQuery = locationState.locationQuery;
    const { userOverride } = mapState;
    const { fetchAtLocation } = locationState;
    const currentMapPosition = {
      lat: mapState.lat,
      lng: mapState.lng,
    };

    // if we're outside pan threshold
    if (outsidePanThreshold) {
      return currentMapPosition;
    }

    if (userGeolocation && !lastLocationQuery) {
      return {
        lat: userGeolocation.lat,
        lng: userGeolocation.lng,
      };
    }

    // Use current map position if we triggered a fetch at the location
    if (fetchAtLocation) {
      return currentMapPosition;
    }

    if (lastLocationQuery?.lat) {
      return {
        lat: lastLocationQuery.lat,
        lng: lastLocationQuery.lng,
      };
    }

    // Use current map position if the user panned the map and we haven't made any queries
    if (userOverride) {
      return currentMapPosition;
    }

    return null;
  }
);

export const fetchParametersSelector = createSelector(
  selectedFeaturesStateSelector,
  placeNameSelector,
  hasNotFoundErrorSelector,
  expandedStoreStateSelector,
  userGeolocationSelector,
  currentFetchCoordsSelector,
  // eslint-disable-next-line complexity
  (
    selectedFeaturesState,
    placeName,
    hasNotFoundError,
    expandedStoreState,
    userGeolocation,
    currentFetchCoords
  ) => {
    const result = {};

    // If we don't have any search parameters, return null.
    if (
      !expandedStoreState &&
      !placeName &&
      !userGeolocation &&
      !currentFetchCoords
    ) {
      return null;
    }

    // If the expanded store is a 404 and we have no other fetch coordinates, return null.
    if (expandedStoreState && hasNotFoundError && !currentFetchCoords) {
      return null;
    }

    if (placeName) {
      result.place = placeName;
    }

    if (selectedFeaturesState.length) {
      result.features = selectedFeaturesState.join();
    }

    if (currentFetchCoords) {
      result.lat = currentFetchCoords.lat;
      result.lng = currentFetchCoords.lng;
    }

    return result;
  }
);

export const fetchUrlSelector = createSelector(
  fetchParametersSelector,
  (fetchParams) => {
    if (!fetchParams) {
      return '';
    }

    return `/bff/locations?${qs.stringify(fetchParams)}`;
  }
);

export const appQueryStringSelector = createSelector(
  selectedFeaturesStateSelector,
  targetMapCoordsSelector,
  placeNameSelector,
  expandedStoreStateSelector,
  (selectedFeaturesState, targetCoords, placeName, expandedStoreState) => {
    const queryItems = [];

    if (expandedStoreState && expandedStoreState.expanded) {
      return '';
    }

    if (selectedFeaturesState.length) {
      queryItems.push(`features=${selectedFeaturesState.join()}`);
    }

    const locationString = [
      round(targetCoords.lat, 6),
      round(targetCoords.lng, 6),
      `${targetCoords.zoomLevel}z`,
    ].join();

    queryItems.push(`map=${locationString}`);

    if (placeName) {
      queryItems.push(`place=${placeName}`);
    }

    return `?${queryItems.join('&')}`;
  }
);

export const outsideZoomThresholdSelector = createSelector(
  configSelector,
  targetMapCoordsSelector,
  (config, targetMapCoords) => targetMapCoords.zoomLevel <= config.zoomThreshold
);

export const hideLocationsSelector = createSelector(
  outsideZoomThresholdSelector,
  showNoStoresCardSelector,
  hasNotFoundErrorSelector,
  (outsideZoomThreshold, showNoStoresCard, showNotFoundCard) => {
    return outsideZoomThreshold && !(showNoStoresCard || showNotFoundCard);
  }
);

export const shouldFetchLocationsSelector = createSelector(
  storeLocatorSelector,
  locationQueryUrlSelector,
  fetchUrlSelector,
  outsideZoomThresholdSelector,
  userOverrideSelector,
  (
    storeLocatorState,
    previousUrl,
    fetchUrl,
    outsideZoomThreshold,
    userOverride
  ) => {
    // store locator hasn't been loaded yet
    if (!storeLocatorState) {
      return false;
    }
    // don't fetch when outside zoom threshold and user pans
    if (outsideZoomThreshold && userOverride) {
      return false;
    } else if (!fetchUrl) {
      return false;
    } else if (!previousUrl) {
      return true;
    }

    const fetchParams = qs.parse(fetchUrl);
    const previousParams = qs.parse(previousUrl);

    // after a place search, the place property will be added to the url. we should ignore
    // this value.
    if (previousParams.place && !fetchParams.place) {
      delete previousParams.place;
    }

    return !isEqual(fetchParams, previousParams);
  }
);

export const showSearchOrZoomMessageSelector = createSelector(
  outsideZoomThresholdSelector,
  hasLocationsSelector,
  fetchAtLocationSelector,
  initialUrlZoomOutsideThresholdSelector,
  (
    outsideZoomThreshold,
    hasLocations,
    fetchingAtLocations,
    initialUrlZoomOutsideThreshold
  ) => {
    // show message when initial zoom on url is outside threshold, but check other logic to
    // prevent selector from always returning true if it was initially true
    return (
      outsideZoomThreshold &&
      !hasLocations &&
      !fetchingAtLocations &&
      initialUrlZoomOutsideThreshold
    );
  }
);

export const showZoomedOutMessageSelector = createSelector(
  outsideZoomThresholdSelector,
  hasLocationsSelector,
  locationsLoadingSelector,
  fetchAtLocationSelector,
  (
    outsideZoomThreshold,
    hasLocations,
    locationsLoading,
    fetchingAtLocations
  ) => {
    return (
      outsideZoomThreshold &&
      hasLocations &&
      !locationsLoading &&
      !fetchingAtLocations
    );
  }
);

export const showGeolocationErrorMessageSelector = createSelector(
  hasLocationsSelector,
  locationsLoadingSelector,
  geolocationFailedSelector,
  geolocationIsLoadingSelector,
  (hasLocations, locationsLoading, geolocationFailed, geolocationIsLoading) => {
    return (
      !geolocationIsLoading &&
      !locationsLoading &&
      geolocationFailed &&
      !hasLocations
    );
  }
);

export const shouldUpdateUrlSelector = createSelector(
  pathnameSelector,
  appQueryStringSelector,
  currentRouteSelector,
  isViewingStoreLocatorSelector,
  inMenuStoreLocatorSelector,
  previousActionSelector,
  (
    pathname,
    search,
    currentRoute,
    isViewingStoreLocator,
    inMenuStoreLocator,
    previousAction
  ) => {
    // for ROUTER_POP we don't want to replace the url but instead go to previously visited route
    if (
      previousAction.type === LOCATION_CHANGE &&
      previousAction.payload.action === 'POP'
    ) {
      return null;
    }
    if (!isViewingStoreLocator || inMenuStoreLocator) {
      return null;
    }
    if (pathname === currentRoute.pathname && search === currentRoute.search) {
      return null;
    }
    return { search, pathname };
  }
);

export const shouldCloseStoreDetailsOnBackButtonPress = createSelector(
  expandedStoreActiveSelector,
  previousActionSelector,
  previousRouteSelector,
  (expandedStoreActive, previousAction, previousRoute) => {
    // if the router location has changed and the store details page is
    // open then indicate that the details page should be closed
    if (
      expandedStoreActive &&
      previousAction.type === LOCATION_CHANGE &&
      previousRoute &&
      includes(previousRoute.pathname, '/store/')
    ) {
      return true;
    }
    return null;
  }
);

export const shouldGoBackOnLocationChange = createSelector(
  previousActionSelector,
  currentRouteSelector,
  (previousAction, currentRoute) => {
    // if the user clicks on a new location and they are on a store details
    // page then indicate that we should have the router go back to the previous route in history
    if (
      previousAction &&
      previousAction.type === 'SELECT_LOCATION' &&
      includes(currentRoute.pathname, '/store/')
    ) {
      return true;
    }
    return null;
  }
);

export const isTabbedStoreListViewSelector = createSelector(
  isMobileViewportSelector,
  signedInSelector,
  (isMobileViewport, signedIn) => isMobileViewport && signedIn
);

// Focus management
export const isContentCratePreventFocusEnabledSelector = createSelector(
  preventFocusTargetsSelector,
  expandedStoreActiveSelector,
  isFilterOverlayOpenSelector,
  (preventFocusTargets, expandedStoreActive, isFilterOpen) => {
    return preventFocusTargets.content || expandedStoreActive || isFilterOpen;
  }
);

export const isHeaderCratePreventFocusEnabledSelector = createSelector(
  preventFocusTargetsSelector,
  expandedStoreActiveSelector,
  isFilterOverlayOpenSelector,
  isMobileViewportSelector,
  (
    preventFocusTargets,
    expandedStoreActive,
    isFilterOpen,
    isMobileViewport
  ) => {
    const mobileFullPage =
      isMobileViewport && (expandedStoreActive || isFilterOpen);
    return mobileFullPage || preventFocusTargets.header;
  }
);

export const storeLocatorErrorTypeSelector = createSelector(
  hasNotFoundErrorSelector,
  placeNameNotFoundSelector,
  showNoStoresCardSelector,
  showZoomedOutMessageSelector,
  showSearchOrZoomMessageSelector,
  showGeolocationErrorMessageSelector,
  (
    hasNotFoundError,
    placeNameNotFound,
    showNoStoresCard,
    showZoomedOutMessage,
    showSearchOrZoomMessage,
    showGeolocationErrorMessage
  ) => {
    if (hasNotFoundError) {
      return 'notFound';
    }
    if (placeNameNotFound) {
      return 'placeNameNotFound';
    }
    if (showNoStoresCard) {
      return 'noStores';
    }
    if (showZoomedOutMessage) {
      return 'zoomedOut';
    }
    if (showSearchOrZoomMessage) {
      return 'searchOrZoom';
    }
    if (showGeolocationErrorMessage) {
      return 'geolocation';
    }
    return null;
  }
);

// re-exports
export * from './location';
export * from './map';
export * from './config';
export * from './selected-features';
export * from './expanded-store';
export * from './nearest-location';
export * from './viewport';
export * from './filter';
