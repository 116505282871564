import { isEmpty } from 'lodash';
import { push } from 'redux-first-history';
import { SELECT_STORE_MENU, CLEAR_STORE_MENU } from './types';
import { postStoreSelectionReturnUrlSelector } from '../../../../ordering/app/state/selectors';

export const confirmStore =
  (selectedStore, currentRoute) => (dispatch, getState) => {
    dispatch({
      type: SELECT_STORE_MENU,
      payload: {
        location: selectedStore,
      },
    });
    const state = getState();
    const postStoreSelectionReturnUrl =
      postStoreSelectionReturnUrlSelector(state);

    const shouldSendUserToCart = currentRoute?.query?.toCart;
    const isThereAPreviousRoute = !isEmpty(postStoreSelectionReturnUrl);

    if (shouldSendUserToCart) {
      dispatch(push('/menu/cart'));
    } else if (isThereAPreviousRoute) {
      dispatch(push(postStoreSelectionReturnUrl));
    } else {
      dispatch(push('/menu'));
    }
  };

export const clearStore = () => ({
  type: CLEAR_STORE_MENU,
  payload: {
    userDidClear: true,
  },
});
