import exEnv from 'exenv';
import { lazyFullPage } from 'shared/app/shell';

import parseStoreLocatorUrl from '../universal/utils/parse-store-locator-url';
import { setUrlState } from './state/actions/application';
import rootReducer from './state/reducers/root-reducer';
import {
  closeStoreDetailsOnBackButtonPress,
  goBackAfterLocationChange,
  updateStoreLocatorUrl,
} from './state/actions/application';
import { fetchLocations } from './state/actions/location';
import {
  shouldCloseStoreDetailsOnBackButtonPress,
  shouldFetchLocationsSelector,
  shouldGoBackOnLocationChange,
  shouldUpdateUrlSelector,
} from './state/selectors';
import {
  FETCH_LOCATIONS,
  REQUEST_NEAREST_LOCATION,
} from './state/actions/types';

export default {
  name: 'storeLocator',
  reducer: rootReducer,
  routes: {
    '/store-locator/*': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'store-locator-page' */ './components/root/store-locator'
          )
      ),
      usesFullPageFlexLayout: true,
      hideGlobalFooter: true,
    },
  },
  initialize: (store) => {
    if (!exEnv.canUseDOM) {
      // Set the starting store locator state for server-side requests to marketing Urls. i.e.
      // Place searches: `?place=Gävle, Sweden`
      // or store details `/store-locator/store/16859/holman-road-9999-holman-rd-nw-seattle-wa-981172041-us`
      const { pathname, search } = store.getState().routes.current;
      const urlState = parseStoreLocatorUrl.default(`${pathname}${search}`);
      if (urlState) {
        store.dispatch(setUrlState(urlState));
      }
    }
  },
  effects: [
    {
      selector: shouldCloseStoreDetailsOnBackButtonPress,
      actionCreator: closeStoreDetailsOnBackButtonPress,
    },
    {
      selector: shouldGoBackOnLocationChange,
      actionCreator: goBackAfterLocationChange,
    },
    {
      selector: shouldUpdateUrlSelector,
      actionCreator: updateStoreLocatorUrl,
    },
    {
      selector: shouldFetchLocationsSelector,
      actionCreator: fetchLocations,
    },
  ],
  asyncActions: [FETCH_LOCATIONS, REQUEST_NEAREST_LOCATION],
};
