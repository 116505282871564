import React, { useEffect, useRef, useState } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import FormContainer from '@starbucks-web/pattern-library/lib/components/form-container';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import AddMoneyBottomSheetForm from './form';
import { reloadCardBalance } from '../../state/actions/card';
import { setSelectedAddMoneyPaymentInstrumentId } from '../../state/actions/cards-management';
import { trackBarcodeCardReloadSuccess } from '../../state/actions/track-event';
import {
  reloadAmountSelectedSelector,
  setReloadAmountSelected,
  svcCardsDataSelector as cardsDataSelector,
  svcCardsLoadingSelector,
} from 'shared/app/bundles/svc-cards';
import FormWithMessaging from 'shared/app/components/form-with-messaging';

import { setBottomSheetUrl, showErrorNotification } from 'shared/app/shell';
import { defaultSvcReloadOptionSelector } from '../../state/selectors';
import { selectedOrPrimaryCardIdSelector } from '../../state/selectors/cards';
import { selectedAddMoneyPaymentInstrumentIdSelector } from '../../state/selectors/ui';
import {
  ADD_PAYMENT,
  defaultOrFirstPaymentInstrumentSelector,
  validPaymentInstrumentsSelector,
} from 'shared/app/bundles/wallet';

import SharedBottomSheet from 'shared/app/components/bottom-sheet-with-messages';
import messages from './messages';
import { sharedCallsToAction, paymentMessages } from 'shared/app/messages';

/* eslint-disable max-statements */
export const AddMoneyBottomSheet = () => {
  const dispatch = useDispatch();
  const { closeModal } = useModalContext();
  // selectors
  const defaultPaymentInstrument = useSelector(
    defaultOrFirstPaymentInstrumentSelector
  );
  const defaultSvcReloadOption = useSelector(defaultSvcReloadOptionSelector);
  const paymentInstruments = useSelector(validPaymentInstrumentsSelector);
  const reloadAmount = useSelector(reloadAmountSelectedSelector);
  const selectedCardId = useSelector(selectedOrPrimaryCardIdSelector);
  const selectedPaymentInstrumentId = useSelector(
    selectedAddMoneyPaymentInstrumentIdSelector
  );
  const svcCards = useSelector(cardsDataSelector);
  const svcCardsLoading = useSelector(svcCardsLoadingSelector);

  // Component State
  const [frapText, setFrapText] = useState(messages.addMoneyButton);
  const [selectedSVCId, setSelectedSVCId] = useState(selectedCardId);

  // Local Variables
  const { formatMessage } = useIntl();
  const formContainerRef = useRef(null);

  useEffect(() => {
    if (selectedPaymentInstrumentId === ADD_PAYMENT) {
      setFrapText(sharedCallsToAction.continue);
    } else {
      setFrapText(messages.addMoneyButton);
    }
    formContainerRef?.current?.updateField({
      input: {
        name: 'paymentInstrument',
        value: selectedPaymentInstrumentId,
      },
    });
  }, [selectedPaymentInstrumentId]);

  const handleFieldsStateChange = (fieldsState) => {
    const newPaymentInstrumentId =
      fieldsState?.fields?.paymentInstrument?.input?.value;
    const newSelectedSVCId = fieldsState?.fields?.svcCard?.input?.value;

    if (selectedPaymentInstrumentId !== newPaymentInstrumentId) {
      dispatch(setSelectedAddMoneyPaymentInstrumentId(newPaymentInstrumentId));
    }

    if (selectedSVCId !== newSelectedSVCId) {
      setSelectedSVCId(newSelectedSVCId);
    }
  };

  const trackSubmitSuccess = (formData) => {
    const { paymentInstrument } = formData;
    const currentPayment = paymentInstruments.find(
      (instrument) => instrument.paymentInstrumentId === paymentInstrument
    );

    trackBarcodeCardReloadSuccess({
      // eslint-disable-next-line camelcase
      payment_method_type: currentPayment?.paymentType,
    });
  };

  const handleSubmit = (formData) => {
    if (selectedPaymentInstrumentId === ADD_PAYMENT) {
      if (
        formData?.amount &&
        formData.amount !== defaultSvcReloadOption.toString()
      ) {
        dispatch(setReloadAmountSelected(formData));
      }
      dispatch(setBottomSheetUrl());
      return dispatch(push('/account/payment-method'));
    }

    dispatch(
      reloadCardBalance({
        closeModal,
        formData,
        onSuccess: trackSubmitSuccess,
      })
    );
  };

  const handleSubmitError = ({ paymentInstrument }) => {
    if (paymentInstrument && paymentInstruments?.length < 1) {
      dispatch(setBottomSheetUrl());
      return dispatch(push('/account/payment-method'));
    }

    const errorMessage = paymentInstrument
      ? paymentMessages.noPaymentMethodSelected
      : sharedCallsToAction.tryAgain;

    dispatch(
      showErrorNotification({
        messageId: errorMessage.id,
      })
    );
  };

  const getFormFields = () => {
    return {
      svcCard: {
        input: {
          value: selectedCardId || '',
        },
      },
      amount: {
        input: {
          value: reloadAmount || defaultSvcReloadOption?.toString(),
        },
      },
      paymentInstrument: {
        input: {
          value: defaultPaymentInstrument?.paymentInstrumentId ?? ADD_PAYMENT,
        },
      },
    };
  };

  return (
    !svcCardsLoading && (
      <SharedBottomSheet
        headingChildren={formatMessage(messages.addMoneyHeading)}
      >
        <div data-e2e="addMoneyBottomSheet">
          <FormContainer
            fields={getFormFields()}
            onError={handleSubmitError}
            onFieldsStateChange={handleFieldsStateChange}
            onSubmit={handleSubmit}
            ref={formContainerRef}
          >
            <FormWithMessaging>
              <AddMoneyBottomSheetForm
                buttonMessage={frapText}
                card={svcCards.find((card) => card.cardId === selectedSVCId)}
              />
            </FormWithMessaging>
          </FormContainer>
        </div>
      </SharedBottomSheet>
    )
  );
};
/* eslint-enable max-statements */

export default AddMoneyBottomSheet;
