import blobToBase64 from 'shared/app/utils/blob-to-base-sixty-four';

import {
  FETCH_BARCODE,
  FETCH_BARCODE_SUCCESS,
  FETCH_BARCODE_ERROR,
} from '../../actions';

export const fetchBarcodes =
  (cardObjects) =>
  (dispatch, getState, { apiFetch }) => {
    if (!Array.isArray(cardObjects)) {
      cardObjects = [cardObjects];
    }
    const url = '/apiproxy/v1/account/cards/barcode';
    const method = 'post';

    cardObjects.forEach((cardObject) => {
      const { cardNumber, cardId } = cardObject;
      const body = { cardNumber };

      dispatch({ type: FETCH_BARCODE, payload: cardId });

      apiFetch(url, { method, body, includeBlob: true })
        .then((resBody) => resBody.blob)
        .then(blobToBase64)
        .then((payload) => {
          dispatch({
            type: FETCH_BARCODE_SUCCESS,
            payload: {
              id: cardId,
              cardNumber,
              uri: payload,
            },
          });
        })
        .catch((error) => {
          dispatch({
            error,
            type: FETCH_BARCODE_ERROR,
            payload: cardId,
          });
        });
    });
  };
