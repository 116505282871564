import {
  SET_URL_STATE,
  COLLAPSE_EXPANDED_STORE,
  SET_PLACE_NAME,
  FETCH_AT_PLACENAME,
  FETCH_LOCATIONS_SUCCESS,
  SELECT_LOCATION,
  MOVE_MAP,
  SET_MAP_INSTANCE,
  REQUEST_NEAREST_LOCATION_SUCCESS,
  SET_FEATURES,
  CLEAR_SELECTED_FEATURES,
} from '../actions/types';
import { GEOLOCATION_SUCCESS } from '../../../../shared/app/bundles/geolocation';

const initialState = {
  lat: null,
  lng: null,
  zoomLevel: null,
  mapInstance: null,
  locatorControlsHeight: 0,
  locatorPageRectangle: null,
  // Flag indicating that the user moved the pan by panning or zooming.
  userOverride: false,
  // Flag indicating that the map should remain in place after an action takes place.
  preventMovement: false,
  // One time flag indicating that the map has updated coordinates on the client-side, used to
  // resolve conflicts between server and client location queries.
  coordinatesUpdated: false,
  initialUrlZoomOutsideThreshold: false,
};

const changedFilterState = {
  userOverride: false,
  preventMovement: true,
};

/* eslint-disable complexity */
const map = (state = initialState, action) => {
  switch (action.type) {
    case SET_URL_STATE: {
      const {
        lat,
        lng,
        zoomLevel,
        expandedStoreNumber,
        initialUrlZoomOutsideThreshold,
      } = action.payload;

      // Match SELECT_LOCATION state on server expanded store loads.
      if (expandedStoreNumber) {
        return Object.assign({}, state, {
          preventMovement: true,
          coordinatesUpdated: true,
        });
      }

      if (lat) {
        return Object.assign({}, state, {
          lat,
          lng,
          zoomLevel,
          userOverride: true,
          initialUrlZoomOutsideThreshold,
        });
      }
    }
    case SET_MAP_INSTANCE:
      return Object.assign({}, state, { mapInstance: action.mapInstance });
    case MOVE_MAP: {
      const { lat, lng, zoomLevel, userOverride } = action.payload;
      return Object.assign({}, state, {
        lat,
        lng,
        zoomLevel,
        userOverride: Boolean(userOverride),
        coordinatesUpdated: true,
      });
    }
    case COLLAPSE_EXPANDED_STORE:
      return Object.assign({}, state, {
        preventMovement: true,
        ...action.payload,
      });
    case SET_PLACE_NAME:
      return Object.assign({}, state, { userOverride: false });
    case SET_FEATURES:
      return Object.assign({}, state, changedFilterState);
    case CLEAR_SELECTED_FEATURES:
      return Object.assign({}, state, changedFilterState);
    case GEOLOCATION_SUCCESS:
      if (!action.payload.userInitiated) {
        return state;
      }
      // If this was done by pushing the geolocate button, we'll
      // get `true` for `action.payload.userInitiated`. In this case, we'll
      // just center on the user and remove the user override.
      return Object.assign({}, state, {
        userOverride: false,
        lat: action.payload.coords.latitude,
        lng: action.payload.coords.longitude,
        coordinatesUpdated: true,
      });
    case REQUEST_NEAREST_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        userOverride: false,
        lat: action.payload.nearestStoreCoordinates.lat,
        lng: action.payload.nearestStoreCoordinates.lng,
        coordinatesUpdated: true,
      });
    case FETCH_AT_PLACENAME:
      return Object.assign({}, state, {
        userOverride: false,
        lat: action.payload.coordinates.lat,
        lng: action.payload.coordinates.lng,
        coordinatesUpdated: true,
      });

    case FETCH_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        preventMovement: false,
      });

    case SELECT_LOCATION:
      return Object.assign({}, state, {
        userOverride: action.payload.userOverride,
        preventMovement: action.payload.preventMovement,
        ...action.payload.mapPosition,
      });
    default:
      return state;
  }
};
/* eslint-enable complexity */

export default map;
