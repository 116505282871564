import {
  lazyFullPage,
  lazyContentCrate,
  lazyHeaderCrate,
} from 'shared/app/shell';

import rootReducer from './state/reducers/root-reducer';
import { fetchAddressList } from './state/action-creators';
import { shouldFetchAddressListSelector } from './state/selectors';

const PaymentHeader = lazyHeaderCrate(
  () =>
    import(
      /* webpackChunkName: 'payment-method-header' */ './components/header-crate'
    )
);

const SecureUIForm = lazyContentCrate(
  () =>
    import(
      /* webpackChunkName: 'secure-ui-form-content' */ './components/secure-ui-form'
    )
);

const GuestPayment = lazyContentCrate(
  () =>
    import(
      /* webpackChunkName: 'secure-ui-form-content' */ './components/guest-payment'
    )
);

export default {
  name: 'accountPaymentMethod',
  reducer: rootReducer,
  routes: {
    '/account/payment-method': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'payment-method-management' */ './components/payment-methods'
          )
      ),
      authRequired: true,
    },
    '/account/payment-method/add/billing-address': {
      headerCrate: PaymentHeader,
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'add-payment-method-content' */ './components/add-payment-method'
          )
      ),
    },
    '/account/payment-method/edit/billing-address': {
      headerCrate: PaymentHeader,
      contentCrate: lazyContentCrate(
        () =>
          import(
            /* webpackChunkName: 'edit-payment-method-content' */ './components/edit-payment-method'
          )
      ),
    },
    '/account/payment-method/add/paypal': {
      fullPageComponent: lazyFullPage(
        () =>
          import(
            /* webpackChunkName: 'paypal-insterstitial' */ './components/paypal-interstitial'
          )
      ),
      authRequired: true,
    },
    '/account/payment-method/add/credit-card': {
      headerCrate: PaymentHeader,
      contentCrate: SecureUIForm,
    },
    '/account/payment-method/edit/credit-card': {
      headerCrate: PaymentHeader,
      contentCrate: SecureUIForm,
    },
    '/account/payment-method/gift/guest/credit-card': {
      headerCrate: PaymentHeader,
      contentCrate: GuestPayment,
    },
  },
  effects: [
    {
      selector: shouldFetchAddressListSelector,
      actionCreator: fetchAddressList,
    },
  ],
  asyncActions: [],
};
