import { trackEvent, trackPageView } from 'shared/app/utils/uo-tracking';

/**
 * @param {{authentication_state: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStreamView = (eventAttributes) =>
  trackPageView('STREAM_VIEW', eventAttributes);

/**
 * @param {{stream_card_id: string, stream_card_type: string, offer_id: string, menu_product_id: string, stream_card_position: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStreamCardClick = (eventAttributes) =>
  trackEvent('STREAM_CARD_CLICK', eventAttributes);

/**
 * @param {{authentication_state: string, stream_card_id: string, stream_card_type: string, offer_id: string, menu_product_id: string, stream_card_position: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStreamCardView = (eventAttributes) =>
  trackPageView('STREAM_CARD_VIEW', eventAttributes);

export const trackStreamFrapScanClick = () =>
  trackEvent('STREAM_FRAP_SCAN_CLICK');

export const trackStreamFrapStartCheckoutClick = () =>
  trackEvent('STREAM_FRAP_START_CHECKOUT_CLICK');
