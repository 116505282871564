import {
  FETCH_PREVIOUS_ORDERS,
  FETCH_PREVIOUS_ORDERS_SUCCESS,
  FETCH_PREVIOUS_ORDERS_ERROR,
  INVALIDATE_MOST_RECENT_ORDER,
} from './types';
import { GET_PREVIOUS_ORDERS } from '../../../universal/gql-operation-ids';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import { selectedStoreShortNumberSelector } from 'shared/app/state/selectors/ordering';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';

import { getDemystifyMenuPreviousFeatureFlag } from 'shared/app/utils/feature-flags';

export const fetchPreviousOrders =
  () =>
  async (dispatch, getState, { gqlFetch }) => {
    const state = getState();

    const locale = localeTagSelector(state);
    const storeNumber = selectedStoreShortNumberSelector(state);

    dispatch({ type: FETCH_PREVIOUS_ORDERS, payload: { locale, storeNumber } });

    const isDemystifyMenuPreviousEnabled =
      await getDemystifyMenuPreviousFeatureFlag();

    return gqlFetch({
      operationId: GET_PREVIOUS_ORDERS,
      allowNonFatalErrors: true, // Display good portion of result
      variables: {
        locale,
        storeNumber,
        limit: isDemystifyMenuPreviousEnabled ? 40 : 10,
      },
      destinationType: API_PROXY_V1,
    })
      .then((payload) => {
        dispatch({
          type: FETCH_PREVIOUS_ORDERS_SUCCESS,
          payload: payload.previousOrders,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_PREVIOUS_ORDERS_ERROR, error });
      });
  };

export const invalidateMostRecentOrder = () => (dispatch) => {
  dispatch({ type: INVALIDATE_MOST_RECENT_ORDER });
};
