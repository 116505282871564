import React, { useState, useEffect } from 'react';
import { isObject } from 'lodash';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Frap from 'shared/app/components/frap-with-messages';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import externalLink from '@starbucks-web/pattern-library/lib/icons/external-link';
import externalLinkMessages from 'shared/app/components/external-link/messages.js';

import { configImagesUrlSelector } from 'shared/app/state/selectors/config';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import {
  doordashStorefrontUrlSelector,
  doordashFaqUrlSelector,
} from '../../state/selectors';

import { getCachedCart } from 'shared/app/utils/cache-helper';

import {
  trackDeliveryView,
  trackDeliveryGetStartedCtaClick,
} from '../../state/actions/track-event';

import RebuildCartDialog, {
  REBUILD_CART_DIALOG_ID,
} from './rebuild-cart-dialog';
import styles from './styles.cssm';
import messages from './messages';
import { commonMessages } from 'shared/app/messages/common-words';

const DeliveryLandingPage = () => {
  const { formatMessage } = useIntl();
  const { openModal } = useModalContext();

  const [cartItemCount, setCartItemCount] = useState(0);

  useEffect(() => {
    getCachedCart().then((cartItems) => {
      if (isObject(cartItems)) {
        setCartItemCount(Object.keys(cartItems).length);
      }
    });
  }, []);

  useEffect(() => {
    trackDeliveryView();
  }, []);

  const isMobileViewport = useSelector(isMobileViewportSelector);
  const doordashStorefrontUrl = useSelector(doordashStorefrontUrlSelector);
  const doordashFaqUrl = useSelector(doordashFaqUrlSelector);

  const configImagesUrl = useSelector(configImagesUrlSelector);
  const deliveryHeroImageUrl = `${configImagesUrl}/delivery/delivery-hero.png`;

  const handleGetStartedClick = () => {
    trackDeliveryGetStartedCtaClick();
    if (cartItemCount > 0) {
      openModal({
        component: RebuildCartDialog,
        ariaLabelledBy: REBUILD_CART_DIALOG_ID,
        componentProps: {
          doordashStorefrontUrl,
        },
      });
    } else {
      window.open(doordashStorefrontUrl, '_blank');
    }
  };

  return (
    <React.Fragment>
      <Helmet title={formatMessage(commonMessages.deliveryLabel)} />
      <div className={`flex flex-column height-100 ${styles.textWrapper}`}>
        <div className={`flex items-center ${styles.deliveryImage}`}>
          <ImageLoader
            alt={formatMessage(messages.deliveryHeroAlt)}
            className="mx-auto"
            height={isMobileViewport ? '218' : '417'}
            lazyLoad={{ enabled: false }}
            src={deliveryHeroImageUrl}
          />
        </div>
        <div
          className={`flex flex-column flex-grow justify-center ${styles.deliveryTextContent}`}
        >
          <div className={styles.headerWrapper}>
            {/* not using the H1 component so that it doesn't steal focus from the toggle at the top of the page */}
            <Heading
              className="text-bold text-center mt3 mb2 lg-mb6 lg-mt9"
              size="lg"
              tagName="h1"
            >
              <FormattedMessage {...messages.deliveryHeading} />
            </Heading>
            <div className="text-center mt2">
              <p className="mb5">
                <FormattedMessage
                  {...messages.deliveryFAQText}
                  values={{
                    ctaFAQs: (content) => (
                      <a
                        className="color-greenAccent text-underline"
                        href={doordashFaqUrl}
                        target="_blank"
                      >
                        {content}
                      </a>
                    ),
                  }}
                />
              </p>
              <Frap
                aria-label={
                  !cartItemCount
                    ? `${formatMessage(messages.getStartedFrap)}, ${formatMessage(externalLinkMessages.externalLinkAria)}`
                    : formatMessage(messages.getStartedFrap)
                }
                className="text-sm valign-middle"
                onClick={handleGetStartedClick}
              >
                <FormattedMessage {...messages.getStartedFrap} />
                {!cartItemCount ? <Icon path={externalLink} /> : null}
              </Frap>
            </div>
          </div>
          <p className="m4 text-center text-xs color-textBlackSoft">
            <FormattedMessage {...messages.doordashLegalText} />
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DeliveryLandingPage;
