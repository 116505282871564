import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import DirectionsLink from 'shared/app/components/directions-link';

import { isMetricSelector } from 'shared/app/state/selectors/config';

import messages from './messages';

export const Address = ({ addressLines }) => {
  if (!addressLines.length) {
    return null;
  }

  return (
    <p>
      {addressLines.map((line, i) => (
        <span className="block" key={i}>
          {line}
        </span>
      ))}
    </p>
  );
};

const Distance = ({ distance, isMetric, formatMessage }) => {
  if (!distance) {
    return null;
  }
  const distanceTemplate = isMetric
    ? messages.distanceKmAway
    : messages.distanceMilesAway;

  const roundedDistance = Math.round(distance * 10) / 10;
  return (
    <p className="color-textBlackSoft" data-e2e="distance">
      {formatMessage(distanceTemplate, { distance: roundedDistance })}
    </p>
  );
};

const Phone = ({ phoneNumber, formatMessage, isMobileViewport }) => {
  if (!phoneNumber) {
    return null;
  }

  return (
    <Button
      className="mb3 lg-mxn3"
      href={`tel:${phoneNumber}`}
      visualStyle={isMobileViewport ? 'default' : 'textOnly'}
    >
      <span className="hiddenVisually">
        {formatMessage(messages.phoneLabel)}
      </span>
      <div>
        <span aria-hidden>
          {formatMessage(messages.call)}
          {`: `}
        </span>
        <span>{phoneNumber}</span>
      </div>
    </Button>
  );
};

const LocationInfo = ({ storeData }) => {
  const { distance } = storeData;
  const { formatMessage } = useIntl();

  const isMobileViewport = useSelector(isMobileViewportSelector);
  const isMetric = useSelector(isMetricSelector);

  return (
    <div>
      <div className="pb5 grid">
        <div className="gridItem size6of12">
          <Address addressLines={storeData.store.address.lines} />
        </div>
        <div className="gridItem size6of12 text-right lg-text-left">
          <Distance
            distance={distance}
            formatMessage={formatMessage}
            isMetric={isMetric}
          />
        </div>
      </div>
      <div className="whiteSpace-noWrap">
        <div>
          <Phone
            formatMessage={formatMessage}
            isMobileViewport={isMobileViewport}
            phoneNumber={storeData.store.phoneNumber}
          />
        </div>
        <div>
          <DirectionsLink storeAddressLines={storeData.store.address.lines} />
        </div>
      </div>
    </div>
  );
};

LocationInfo.propTypes = {
  storeData: PropTypes.object.isRequired,
};

export default LocationInfo;
