import { createSelector } from 'reselect';

import { configSelector } from 'shared/app/shell';

export const mainNavLinksConfigSelector = createSelector(
  configSelector,
  (config) => {
    return config?.mainNavLinks?.filter((mainNavItem) => {
      return mainNavItem;
    });
  }
);
