import { defineMessages } from 'react-intl';

const messages = defineMessages({
  checkInHeading: {
    id: 'pickup.checkInHeading',
    defaultMessage: 'Check-in',
    description: 'Heading for the check-in options',
  },
  checkInClosedHeading: {
    id: 'pickup.checkInClosedHeading',
    defaultMessage: 'Limited pickup',
    description: 'Heading for when check-in options are closed',
  },
  newLabel: {
    id: 'pickup.newLabel',
    defaultMessage: 'New',
    description: 'Label to notify about new pickup options',
  },
  pickupOptionsHeading: {
    id: 'pickup.optionsHeading',
    defaultMessage: 'Or visit us the usual way…',
    description: 'Heading for the pick up options',
  },
  pickupOnlyOptionsHeading: {
    id: 'pickup.pickUpOnlyOptionsHeading',
    defaultMessage: 'Visit us the usual way…',
    description: 'Heading for when ONLY pickup options are available',
  },
  curbsideOptionHeading: {
    id: 'pickup.curbsideOptionHeading',
    defaultMessage: 'Curbside',
    description: 'Heading for the curbside option',
  },
  curbsideOptionMessage: {
    id: 'pickup.curbsideOptionMessage',
    defaultMessage: 'A barista will bring your order to you in your vehicle',
    description: 'Message for the curbside option',
  },
  curbsideOptionClosed: {
    id: 'pickup.curbsideOptionClosed',
    defaultMessage: 'Curbside pickup is closed right now.',
    description: 'Message when Curbide Pickup is closed',
  },
  outdoorOptionHeading: {
    id: 'pickup.outdoorOptionHeading',
    defaultMessage: 'Outdoor',
    description: 'Heading for the outdoor option',
  },
  outdoorOptionMessage: {
    id: 'pickup.outdoorOptionMessage',
    defaultMessage: 'A barista will bring your order to you on the sidewalk',
    description: 'Message for the outdoor option',
  },
  inStoreOptionHeading: {
    id: 'pickup.inStoreOptionHeading',
    defaultMessage: 'In store',
    description: 'Heading for the in-store option',
  },
  outdoorPickUpHeading: {
    id: 'pickup.outdoorPickUpHeading',
    defaultMessage: 'Outdoor pickup',
    description: 'Heading for outdoor pickup page',
  },
  outdoorPickUpMessage: {
    id: 'pickup.outdoorPickUpMessage',
    defaultMessage: 'Let us know when you arrive',
    description: 'Heading for outdoor pickup page',
  },
  outdoorOptionClosed: {
    id: 'pickup.outdoorOptionClosed',
    defaultMessage: 'Outdoor pickup is closed right now.',
    description: 'Message when Outdoor Pickup is closed',
  },
  driveThruOptionHeading: {
    id: 'pickup.driveThruOptionHeading',
    defaultMessage: 'Drive-thru',
    description: 'Heading for the in-store option',
  },
  driveThruOptionMessage: {
    id: 'pickup.driveThruOptionMessage',
    defaultMessage:
      'When you arrive, head straight to the drive-thru and ask the barista for your order',
    description: 'Message for the in-store option',
  },
});

export default messages;
