import * as messages from '../messages';
import { seasonConfig } from './season-config';

export const getTimeOfDay = (hour) => {
  if (hour < 12) {
    return 'morning';
  }
  if (hour < 17) {
    return 'afternoon';
  }
  return 'evening';
};

export const getGreeting = () => {
  const today = new Date();
  const timeOfDay = getTimeOfDay(today.getHours());

  const seasons = Object.keys(seasonConfig);

  const currentSeason = seasons.find(
    (seasonKey) =>
      today >= seasonConfig[`${seasonKey}`].start &&
      today <= seasonConfig[`${seasonKey}`].end
  );

  const message = `${currentSeason}Messages`;

  if (!currentSeason) {
    return {
      message: messages.defaultMessages[timeOfDay],
      emoji: seasonConfig.default.emoji[timeOfDay],
    };
  }

  return {
    message: messages[message][timeOfDay],
    emoji: seasonConfig[currentSeason].emoji[timeOfDay],
  };
};
