import { isEmpty } from 'lodash';
const ms = require('milliseconds');
import { push } from 'redux-first-history';

import logOptionsMissingSkus from '../../util/log-options-missing-skus';
import { sortSelectedOptions } from '../../util/cart-content-util';
import getThumbnailUri from 'shared/app/utils/get-thumbnail-uri';
import {
  scheduledTimeSlotSelector,
  selectedStoreSelector,
  selectedStoreSupportsScheduledOrderingSelector,
} from 'shared/app/state/selectors/ordering';
import { deleteGuestCookies } from 'shared/app/state/action-creators/guest';
import { signedInSelector } from 'shared/app/bundles/user';
import { showUnexpectedErrorNotification } from 'shared/app/shell';
import {
  addDigitalSvcCard,
  reloadAmountSelectedSelector,
  clearReloadAmountSelected,
  getSvcCardBalance,
  reloadSvcCardBalance,
} from 'shared/app/bundles/svc-cards';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';
import { fetchWallet, PAYMENT_TYPE_SVC } from 'shared/app/bundles/wallet';
import PickupOverlay from 'shared/app/components/order-pickup-overlay';
import { accertifyDataCollectorIsEnabledSelector } from 'shared/app/state/selectors/accertify-data-collector';
import { showErrorNotification } from 'shared/app/shell/state/action-creators/notifications';
import { showCodedErrorNotification } from 'shared/app/utils/show-coded-error-notification';
import runSequentially from 'shared/app/utils/run-sequentially';
import { getFraudReputation } from 'shared/app/utils/iovation';
import { createGuestSession } from 'shared/app/state/action-creators/guest';

import {
  deliveryTypeSelector,
  slimCartSelector,
  appliedCartOffersSelector,
  cartHasOfferAppliedSelector,
  currentPickupOptionSelector,
  pricingLoadingSelector,
} from '../../state/selectors';

import { transformCart } from '../../../universal/utils/transform-cart';

import {
  getNotificationForPricingError,
  UNEXPECTED_ERROR,
  REWARD_NOT_APPLIED,
} from './errors/pricing';
import {
  getNotificationForSubmitError,
  DIGITAL_CARD_PROVISION_ERROR,
  INSUFFICIENT_BALANCE,
} from './errors/submit-order';
import {
  TimeSlotRescheduledDialog,
  TIME_SLOT_RESCHEDULED_DIALOG_ID,
} from '../../components/cart/time-slot-rescheduled-dialog';

import {
  ADD_PRODUCT_TO_CART,
  ADD_OOS_RECOMMENDATION_TO_CART,
  CLEAR_CURRENT_CART,
  SET_APPLY_CART_OFFER,
  CHANGE_QUANTITY,
  FETCH_ORDER_PRICING,
  FETCH_ORDER_PRICING_SUCCESS,
  FETCH_ORDER_PRICING_ERROR,
  CLEAR_ORDER_PRICING,
  CLEAR_CART_AVAILABLE_REWARDS,
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_ERROR,
  CLEAR_ORDER_STATUS,
  UPDATE_ORDER_DELIVERY_TYPE,
  REWARD_REDEEMED,
  HIDE_ADDED_PRODUCT_NOTIFICATION,
  SELECT_PICKUP_OPTION,
  FETCH_STORE_TIME_SLOTS,
  FETCH_STORE_TIME_SLOTS_SUCCESS,
} from './types';

import { PICKUP_OVERLAY_SEEN } from 'shared/app/state/action-creators/types';

import {
  GET_STORE_TIME_SLOTS,
  PRICE_ORDER,
  PLACE_ORDER,
  PRICE_ORDER_GUEST,
} from '../../../universal/gql-operation-ids';

import { getOrderPickupTime } from '../actions/order-pickup-time';

import { CLEAR_STORE_CONFIRMATION } from 'store-locator/app/state/actions/types';
import { commonMessages } from 'shared/app/messages';
import { getShortStoreNumber } from 'shared/app/bundles/menu/util/get-filter-query';

export const hideAddedProductNotification = () => ({
  type: HIDE_ADDED_PRODUCT_NOTIFICATION,
});

export const changeQuantity = ({ delta, itemId }) => ({
  type: CHANGE_QUANTITY,
  payload: { delta, itemId },
});

export const clearPricing = () => ({
  type: CLEAR_ORDER_PRICING,
});

export const clearOrderStatus = () => ({
  type: CLEAR_ORDER_STATUS,
});

export const clearCartAvailableRewards = () => (dispatch) =>
  dispatch({ type: CLEAR_CART_AVAILABLE_REWARDS });

export const clearCartItems = () => (dispatch) =>
  dispatch({ type: CLEAR_CURRENT_CART });

export const transformItemToAdd = ({
  product,
  sizeCode,
  selectedOptions,
  quantity,
}) => {
  // Temporarily reporting the condition of a missing sku to troubleshoot
  // product data. The reporting can be remove once the root cause of the
  // missing skus has been identified.
  logOptionsMissingSkus(selectedOptions);

  // Did sizeCode get passed in? If so, find a size in the product
  // where its sizeCode matches the one passed in.
  const size = sizeCode
    ? (product?.sizes || product?.forms?.[0]?.sizes).find(
        (_size) => _size.sizeCode === sizeCode
      )
    : product?.sizes?.[0] || product?.forms?.[0]?.sizes?.[0];

  const productImage = getThumbnailUri(product);

  const sortedOptions = sortSelectedOptions(selectedOptions);
  return {
    product,
    size,
    quantity,
    selectedOptions: sortedOptions,
    productImage,
  };
};

export const editItemInCart =
  ({
    quantity,
    product,
    sizeCode,
    selectedOptions,
    editedItemId,
    originalCartItem,
  }) =>
  (dispatch) => {
    if (!product || !sizeCode || !quantity) {
      dispatch(showUnexpectedErrorNotification());
      throw new Error('Product data is invalid');
    }

    if (editedItemId !== originalCartItem) {
      dispatch(changeQuantity({ delta: -1, itemId: originalCartItem }));
      dispatch({
        type: ADD_PRODUCT_TO_CART,
        payload: transformItemToAdd({
          product,
          sizeCode,
          selectedOptions,
          quantity,
        }),
      });
    }
    dispatch(push('/menu/cart'));
  };

export const addProductToCart =
  ({
    outOfStockItemKey,
    product,
    quantity,
    sizeCode,
    selectedOptions,
    // everything else received is trackingMetadata
    // currently these properties can include:
    // --------------------------
    // availabilityWhenAdded
    // deepbrewRecommendationType
    // isRecommendedProduct
    // isUsual
    // moodName
    // productAddSource
    // recommendationId
    // recommendationRank
    // recommendationType
    // storeNumber
    ...trackingMetadata
  }) =>
  (dispatch) => {
    if (!product || !sizeCode || !quantity) {
      dispatch(showUnexpectedErrorNotification());
      throw new Error('Product data is invalid');
    }

    const transformedItemForCart = transformItemToAdd({
      product,
      quantity,
      selectedOptions,
      sizeCode,
    });

    const actionType = outOfStockItemKey
      ? ADD_OOS_RECOMMENDATION_TO_CART
      : ADD_PRODUCT_TO_CART;
    return dispatch({
      type: actionType,
      payload: {
        ...transformedItemForCart,
        ...trackingMetadata,
        ...(outOfStockItemKey && { outOfStockItemKey }),
      },
    });
  };

const consumptionTypeMap = {
  'ConsumeInStore': 'CONSUME_IN_STORE',
  'ConsumeOutOfStore': 'CONSUME_OUT_OF_STORE',
};

const collectionTypeMap = {
  '16': 'IN_STORE',
  'DT': 'DRIVE_THRU',
  'CX': 'CURBSIDE',
  '17': 'OUTDOOR',
};

export const selectPickupOption =
  ({ pickupOption }) =>
  (dispatch) => {
    return dispatch({
      type: SELECT_PICKUP_OPTION,
      payload: {
        pickupOption,
      },
    });
  };
/**
 * FetchStoreTimeSlots
 *
 * The mockState parameter when passed in results in
 * a mocked response as follows:
 *
 * 0: All slots available
 * 1: First slot unavailable
 * 2: No slots available
 * 3: Next slot is not available and ONLY one slot is available
 * 4: Store closing soon, so Orchestra filters out time slots with status = "Closed". Return less than 12 slots.
 * 5: mobileOrderAvailability = "NOT_READY"
 * 6: Empty time slot array
 */

export const fetchStoreTimeSlots =
  (mockState) =>
  (dispatch, getState, { gqlFetch }) => {
    const state = getState();

    dispatch({ type: FETCH_STORE_TIME_SLOTS });

    const selectedStore = selectedStoreSelector(state)?.store || {};
    const storeNumber = selectedStore.storeNumber;
    const opts = {
      operationId: GET_STORE_TIME_SLOTS,
      destinationType: API_PROXY_V1,
      variables: {
        storeNumber,
        mockState,
      },
    };
    return gqlFetch(opts).then((data) => {
      return dispatch({
        type: FETCH_STORE_TIME_SLOTS_SUCCESS,
        payload: data,
      });
    });
  };

/* eslint-disable max-statements */
export const fetchPricing =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    // Dispatch immediately so that reducer sets `pricingNeedsFetching` to false
    // and fetchPricing is called only once
    const state = getState();

    const isPricingLoading = pricingLoadingSelector(state);

    if (isPricingLoading) {
      return;
    }

    dispatch({ type: FETCH_ORDER_PRICING });

    const cart = slimCartSelector(state);
    const isSignedIn = signedInSelector(state);
    const selectedStore = selectedStoreSelector(state)?.store || {};
    const storeNumber = selectedStore.storeNumber;

    const offers = appliedCartOffersSelector(state);
    const currentCollectionType =
      collectionTypeMap[currentPickupOptionSelector(state)] || 'IN_STORE';
    const currentConsumptionType =
      consumptionTypeMap[deliveryTypeSelector(state)] || 'CONSUME_OUT_OF_STORE';

    const offerCodes = offers.length
      ? offers.map((offer) => {
          return { code: offer.code };
        })
      : [];

    if (isEmpty(cart)) {
      return;
    }

    const opts = {
      operationId: isSignedIn ? PRICE_ORDER : PRICE_ORDER_GUEST,
      destinationType: API_PROXY_V1,
      variables: {
        order: {
          cart: {
            items: transformCart(cart),
            offers: offerCodes,
          },
          fulfillment: {
            consumptionType: currentConsumptionType,
            collectionType: currentCollectionType,
          },
          storeNumber,
        },
      },
    };
    return gqlFetch(opts)
      .then((data) => {
        if (data.priceOrder.__typename === 'OpenAPIError') {
          dispatch({
            type: FETCH_ORDER_PRICING_ERROR,
            payload: new Error('There was an error getting the order pricing.'),
          });
          dispatch(
            showCodedErrorNotification(
              data.priceOrder.code,
              getNotificationForPricingError
            )
          );
          return;
        }
        const expiresAt = ms.seconds(data.priceOrder.expiresIn) + Date.now();
        dispatch({
          type: FETCH_ORDER_PRICING_SUCCESS,
          payload: {
            ...data.priceOrder,
            store: {
              ...data.priceOrder.store,
              storeNumber,
              ...selectedStore,
            },
            expiresAt,
          },
        });

        // check if the intended offer was not applied during pricing and clear the offer if so.
        return offers?.map((offer) => {
          const foundOffer = (data.priceOrder.cart.offers || []).find(
            (cartOffer) => cartOffer.code === offer.code.toString()
          );
          if (foundOffer && !foundOffer.hasBeenApplied) {
            dispatch({ type: SET_APPLY_CART_OFFER, payload: [] });
            if (foundOffer.description) {
              return dispatch(
                showErrorNotification({ body: foundOffer.description })
              );
            }
            dispatch(
              showCodedErrorNotification(
                REWARD_NOT_APPLIED,
                getNotificationForPricingError
              )
            );
          }
        });
      })
      .catch((err) => {
        if (err?.guestSessionExpired) {
          runSequentially(
            () => dispatch(deleteGuestCookies()),
            async () => {
              const reputation = await getFraudReputation();
              dispatch(createGuestSession({ reputation }));
            }
          );
        }
        // SpecialCase errors are handled by orchestra and meant to simplify how some errors are grouped.
        // https://docs.starbucks.com/pages/viewpage.action?pageId=374514123
        const { code, specialCase, message } = err || {};
        dispatch({ type: FETCH_ORDER_PRICING_ERROR, payload: err });
        dispatch(
          showCodedErrorNotification(
            specialCase || code,
            getNotificationForPricingError,
            { translatedMessage: message }
          )
        );
      });
  };
/* eslint-enable max-statements */

export const updateOrderDeliveryType = (type) => (dispatch, getState) => {
  if (type === deliveryTypeSelector(getState())) {
    return;
  }
  runSequentially(
    () => dispatch({ type: UPDATE_ORDER_DELIVERY_TYPE, payload: type }),
    () => dispatch(fetchPricing())
  );
};

export const validateOrderPricing = ({ pricing, cardId, isGuest = false }) => {
  if (!pricing) {
    return {
      error: new Error('Pricing request is missing from the store.'),
      code: UNEXPECTED_ERROR,
    };
  }

  const { summary: { price } = {}, orderId } = pricing;

  if (!orderId) {
    return {
      error: new Error(`
        Missing transaction data.
        orderId: ${orderId}
      `),
      code: UNEXPECTED_ERROR,
    };
  }

  if (!isGuest && (parseFloat(price) < 0 || !cardId)) {
    return {
      error: new Error(`
        Insufficient balance on card or missing cardId.
        totalAmount: ${price}, cardId: ${cardId}
      `),
      code: INSUFFICIENT_BALANCE,
    };
  }

  return { orderId, price };
};

export const submitOrder =
  ({
    formatMessage,
    openModal,
    closeModal,
    onSuccess,
    onError,
    paymentId,
    tender,
    tipAmount,
  }) =>
  async (dispatch, getState, { gqlFetch }) => {
    const state = getState();
    const pricing = getState()?.ordering?.order?.pricing;
    const {
      orderId,
      price,
      error: validationError,
      code,
    } = validateOrderPricing({ pricing, cardId: paymentId });

    if (validationError && code) {
      dispatch({
        type: SUBMIT_ORDER_ERROR,
        payload: validationError,
      });
      dispatch(showCodedErrorNotification(code, getNotificationForSubmitError));
      onError && onError(code);
      return;
    }
    const selectedStore = selectedStoreSelector(state)?.store;
    const scheduledTimeSlot = scheduledTimeSlotSelector(state);
    const isScheduledOrderingStore =
      selectedStoreSupportsScheduledOrderingSelector(state);
    const variables = {
      subInp: {
        orderId,
        storeNumber: selectedStore?.storeNumber,
        tenders: [{ id: paymentId, tender, amount: price }],
        tipAmount: parseFloat(tipAmount),
        ...(isScheduledOrderingStore &&
          scheduledTimeSlot && {
            scheduledOrdering: scheduledTimeSlot,
          }),
      },
    };

    dispatch({ type: SUBMIT_ORDER });

    return gqlFetch({
      operationId: PLACE_ORDER,
      variables,
      destinationType: API_PROXY_V1,
      includeRisk: true,
      includeAccertify: accertifyDataCollectorIsEnabledSelector(state),
    })
      .then((data) => {
        if (!data?.submitOrder) {
          dispatch({
            type: SUBMIT_ORDER_ERROR,
            payload: new Error('No valid service time.'),
          });
          dispatch(
            showCodedErrorNotification(
              UNEXPECTED_ERROR,
              getNotificationForSubmitError
            )
          );
          onError && onError(UNEXPECTED_ERROR);
          return;
        }

        const cartHasAppliedOffer = cartHasOfferAppliedSelector(getState());

        const checkOfferRedemption = () => {
          if (!cartHasAppliedOffer) {
            return;
          }
          return dispatch({ type: REWARD_REDEEMED });
        };

        const reloadAmountSelected = reloadAmountSelectedSelector(getState());

        runSequentially(
          () =>
            dispatch(
              getOrderPickupTime({
                orderId,
                shortStoreNumber: getShortStoreNumber(
                  selectedStore?.storeNumber
                ),
              })
            ),
          () => closeModal(),
          () => dispatch(push('/menu/previous')),
          () =>
            dispatch({ type: SUBMIT_ORDER_SUCCESS, payload: data.submitOrder }),
          () =>
            openModal({
              component: PickupOverlay,
              modalRootProps: {
                'aria-label': formatMessage(commonMessages.success),
              },
              onClose: () => dispatch({ type: PICKUP_OVERLAY_SEEN }),
            }),
          () => checkOfferRedemption(),
          () => dispatch(clearPricing()),
          () => {
            if (tender === PAYMENT_TYPE_SVC) {
              dispatch(getSvcCardBalance(paymentId));
            }
          },
          () => dispatch(fetchWallet()),
          () => dispatch({ type: CLEAR_STORE_CONFIRMATION }),
          () => reloadAmountSelected && dispatch(clearReloadAmountSelected())
        );

        onSuccess &&
          onSuccess({
            orderResponse: data.submitOrder,
            orderPricing: pricing,
          });
      })
      .catch((error) => {
        const { code: errorCode, message } = error;

        dispatch({ type: SUBMIT_ORDER_ERROR, payload: error });

        if (
          errorCode === '110000' &&
          error.specialCase === 'SLOT_UNAVAILABLE'
        ) {
          dispatch(fetchStoreTimeSlots());
          openModal({
            ariaLabelledBy: TIME_SLOT_RESCHEDULED_DIALOG_ID,
            component: TimeSlotRescheduledDialog,
          });
        } else {
          dispatch(
            showCodedErrorNotification(
              errorCode,
              getNotificationForSubmitError,
              {
                translatedMessage: message,
              }
            )
          );
          onError && onError(error.code);
        }
      });
  };

export const loadAndSubmitOrder =
  ({
    formatMessage,
    formData,
    openModal,
    closeModal,
    tender,
    tipAmount,
    ...other
  }) =>
  (dispatch) => {
    const { payment } = formData;
    dispatch(reloadSvcCardBalance(formData))
      .then(() => {
        dispatch(
          submitOrder({
            formatMessage,
            openModal,
            closeModal,
            paymentId: payment,
            tender,
            tipAmount,
            ...other,
          })
        );
      })
      .catch((error) => {
        const { code } = error;
        dispatch(
          showCodedErrorNotification(code, getNotificationForSubmitError)
        );
      });
  };

export const applyOffer =
  ({ code, stars, type, couponId }) =>
  (dispatch) => {
    dispatch({
      type: SET_APPLY_CART_OFFER,
      payload: code ? [{ code, stars, type, couponId }] : [],
    });
  };

export const addDigitalCardAtCheckout =
  (showPaymentSummaryBottomSheet) => (dispatch) => {
    return dispatch(addDigitalSvcCard())
      .then(() => showPaymentSummaryBottomSheet())
      .catch(() => {
        return dispatch(
          showCodedErrorNotification(
            DIGITAL_CARD_PROVISION_ERROR,
            getNotificationForSubmitError
          )
        );
      });
  };
