import qs from 'query-string';

import {
  SET_URL_STATE,
  FETCH_ERROR,
  HIGHLIGHT_LOCATION,
  UNHIGHLIGHT_LOCATION,
  FETCH_LOCATIONS,
  SELECT_LOCATION,
  SET_PLACE_NAME,
  PLACE_NAME_NOT_FOUND,
  FETCH_LOCATIONS_SUCCESS,
  UPDATE_LOCATIONS_NEAR_STORE,
  FETCH_AT_PLACENAME,
  MOVE_MAP,
  REQUEST_NEAREST_LOCATION_SUCCESS,
  FAVORITE_LOCATION_PENDING,
  FAVORITE_LOCATION_SUCCESS,
  FAVORITE_LOCATION_FAILURE,
} from '../actions/types';

import {
  GEOLOCATION_START,
  GEOLOCATION_SUCCESS,
} from 'shared/app/bundles/geolocation';

import { FETCH_PROFILE } from 'shared/app/bundles/user/actions';

const initialState = {
  error: null,
  loading: false,
  locations: [],
  selectedStoreNumber: null,
  highlightedStoreNumber: null,
  locationQuery: null,
  locationQueryUrl: '',
  placeName: '',
  placeNameNotFound: false,
  fetchAtLocation: false,
  shouldScroll: false,
  geolocationSearch: false,
};

/* eslint-disable complexity */
const location = (state = initialState, action) => {
  switch (action.type) {
    case SET_URL_STATE:
      const { expandedStoreNumber } = action.payload;
      if (action.payload.place && !expandedStoreNumber) {
        return Object.assign({}, state, { placeName: action.payload.place });
      }
      return state;
    case FETCH_LOCATIONS: {
      const { url, locationQuery } = action.payload;
      return Object.assign({}, state, {
        error: null,
        loading: true,
        locationQueryUrl: url,
        locationQuery,
        placeNameNotFound: false,
      });
    }

    case FETCH_LOCATIONS_SUCCESS:
      // Don't update selected store if a store is expanded

      const selectedStoreNumber =
        state.selectedStoreNumber && action.payload.expandedStoreNumber
          ? state.selectedStoreNumber
          : action.payload.selectedStoreNumber;
      const { locationQuery } = state;
      let locationQueryObj = null;

      // if existing locationQuery has place property but there are no coordinates, it was
      // due to a server-side geocode - to allow the logic to continue working client-side,
      // construct and insert locationQuery and locationQueryUrl
      if (locationQuery?.place && !locationQuery.lat) {
        const coordinates = action.payload.data.coordinates;
        const params = Object.assign({}, state.locationQuery, {
          ...coordinates,
        });
        const paramString = qs.stringify(params);

        locationQueryObj = {
          locationQuery: coordinates,
          locationQueryUrl: `/bff/locations?${paramString}`,
        };
      }

      return Object.assign({}, state, locationQueryObj, {
        loading: false,
        locations: action.payload.stores,
        selectedStoreNumber,
        fetchAtLocation: false,
      });

    case UPDATE_LOCATIONS_NEAR_STORE:
      const coordinates = action.payload.coordinates;
      return Object.assign({}, state, {
        loading: false,
        locations: action.payload.stores,
        selectedStoreNumber: action.payload.selectedStoreNumber,
        fetchAtLocation: false,
        locationQuery: coordinates,
        locationQueryUrl: `/bff/locations?${qs.stringify(coordinates)}`,
      });

    case SELECT_LOCATION:
      return Object.assign({}, state, {
        selectedStoreNumber: action.payload.locationNumber,
        shouldScroll: !state.shouldScroll,
        highlightedStoreNumber: null,
      });
    case HIGHLIGHT_LOCATION:
      return Object.assign({}, state, {
        highlightedStoreNumber: action.locationNumber,
      });
    case UNHIGHLIGHT_LOCATION:
      return Object.assign({}, state, {
        highlightedStoreNumber: null,
      });
    case FETCH_ERROR:
      return Object.assign({}, state, {
        error: action.err,
        loading: false,
      });

    case FETCH_PROFILE:
    case GEOLOCATION_START:
      // if the user forcibly wants to geolocate, it's no
      // different than going to `/store-locator` so
      // we also want to reset some values to a blank state
      // in that case, and the rest should just happen.
      // Also in the case of switching from guest to signed user,
      // we want to reset to a blank state.

      const updateMapChanges =
        action.payload?.userInitiated || action.type === FETCH_PROFILE
          ? {
              placeName: '',
              placeNameNotFound: false,
              locations: [],
              locationQuery: null,
              locationQueryUrl: '',
              resultCoordinates: null,
              geolocationSearch: true,
            }
          : null;

      return Object.assign({}, state, updateMapChanges);

    case GEOLOCATION_SUCCESS:
      // If we haven't fetched locations yet, then we're on the initial
      // geolocation search. If we have, return state early and do nothing.
      if (state.locationQuery) {
        return state;
      }

      return Object.assign({}, state, { geolocationSearch: true });
    case SET_PLACE_NAME:
      return Object.assign({}, state, {
        placeName: action.payload.placeName,
        placeNameNotFound: false,
      });
    case PLACE_NAME_NOT_FOUND:
      return Object.assign({}, state, {
        placeName: '',
        placeNameNotFound: true,
        locations: [],
        loading: false,
      });
    case REQUEST_NEAREST_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        fetchAtLocation: true,
        placeName: '',
        geolocationSearch: false,
      });
    case FETCH_AT_PLACENAME:
      return Object.assign({}, state, {
        fetchAtLocation: true,
        placeName: action.payload.placeName,
        placeNameNotFound: false,
        geolocationSearch: false,
      });
    case MOVE_MAP:
      return Object.assign({}, state, {
        placeName: '',
        placeNameNotFound: false,
        geolocationSearch: false,
      });
    case FAVORITE_LOCATION_PENDING:
      return Object.assign({}, state, {
        locations: state.locations.map((currentLocation) => {
          if (
            currentLocation.store.storeNumber === action.payload.storeNumber
          ) {
            currentLocation.isFavorite = action.payload.isFavorite;
            currentLocation.favoritePending = true;
          }
          return currentLocation;
        }),
      });
    case FAVORITE_LOCATION_SUCCESS:
    case FAVORITE_LOCATION_FAILURE:
      return Object.assign({}, state, {
        locations: state.locations.map((currentLocation) => {
          if (
            currentLocation.store.storeNumber === action.payload.storeNumber
          ) {
            currentLocation.isFavorite = action.payload.isFavorite;
            currentLocation.favoritePending = false;
          }
          return currentLocation;
        }),
      });
    default:
      return state;
  }
};
/* eslint-enable complexity */

export default location;
