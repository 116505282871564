import { defineMessages } from 'react-intl';

export default defineMessages({
  manageCard: {
    id: 'accountCards.shared.manageCard',
    defaultMessage: 'Manage card',
    description: 'Title or CTA to manage stored value card',
  },
  addMoney: {
    id: 'accountCards.shared.addMoney',
    defaultMessage: 'Add money',
    description: 'Button to add money to a Stored Value Card',
  },
  cardImageAltText: {
    id: 'accountCards.manageCardImageLink.manageCard',
    defaultMessage: 'Manage card with nickname: { cardNickname }',
    description:
      "Text for the card's image to indicate selecting the image will navigate to the manage card page",
  },
});
