import { trackEvent, trackPageView } from 'shared/app/utils/uo-tracking';

/**
 * Serializes event attribute values that are arrays, such as store_finder_filter_type.
 * @param {Object} attrs - Event attributes to be serialized.
 * @returns {Object} Modified attributes
 */
const serializeAttributes = (attrs = {}) => {
  for (const [key, value] of Object.entries(attrs)) {
    if (Array.isArray(value)) {
      attrs[key] = value.join(',');
      continue;
    }
    if (typeof attrs[key] === 'object') {
      attrs[key] = JSON.stringify(attrs[key]);
      continue;
    }
  }
  return attrs;
};

/**
 * @param {{store_finder_filter_type: string, count_of_stores_shown: number, default_list_tab: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStoreFinderView = (eventAttributes) =>
  trackPageView('STORE_FINDER_VIEW', serializeAttributes(eventAttributes));

/**
 * @param {{store_finder_filter_type: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStoreFinderFilterClick = (eventAttributes) =>
  trackEvent('STORE_FINDER_FILTER_CLICK', serializeAttributes(eventAttributes));

/**
 * @param {{store_finder_filter_type: string, count_of_stores_shown: number}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStoreFinderNearbyClick = (eventAttributes) =>
  trackEvent('STORE_FINDER_NEARBY_CLICK', serializeAttributes(eventAttributes));

/**
 * @param {{store_finder_filter_type: string, count_of_stores_shown: number}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStoreFinderPreviousClick = (eventAttributes) =>
  trackEvent(
    'STORE_FINDER_PREVIOUS_CLICK',
    serializeAttributes(eventAttributes)
  );

/**
 * @param {{count_of_stores_shown: number}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStoreFinderFavoritesClick = (eventAttributes) =>
  trackEvent('STORE_FINDER_FAVORITES_CLICK', eventAttributes);

/**
 * @param {{store_id: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStoreFinderDetailsView = (eventAttributes) =>
  trackPageView('STORE_FINDER_DETAILS_VIEW', eventAttributes);

/**
 * @param {{distance_to_search_location: number}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackStoreFinderAddressSearchSubmit = (eventAttributes) =>
  trackEvent('STORE_FINDER_ADDRESS_SEARCH_SUBMIT', eventAttributes);

/**
 * @param {{store_id: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackMOPStoreSelect = (eventAttributes) =>
  trackEvent('MOP_STORE_SELECT', eventAttributes);

export const trackDeliveryView = () => trackPageView('DELIVERY_VIEW');

export const trackDeliveryGetStartedCtaClick = () =>
  trackEvent('DELIVERY_GET_STARTED_CTA_CLICK');

export const trackDeliveryRebuildCartDialogView = () =>
  trackPageView('DELIVERY_REBUILD_CART_DIALOG_VIEW');

export const trackDeliveryRebuildCartDialogGotItCtaClick = () =>
  trackEvent('DELIVERY_REBUILD_CART_DIALOG_GOT_IT_CTA_CLICK');
