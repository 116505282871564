import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@starbucks-web/pattern-library/lib/components/heading/';
import { scheduledTimeSlotLabelSelector } from 'shared/app/state/selectors/ordering';

import Dialog from '@starbucks-web/pattern-library/lib/components/dialog';

import { sharedCallsToAction } from 'shared/app/messages';
import messages from './messages';

export const TIME_SLOT_RESCHEDULED_DIALOG_ID = 'TimeSlotRescheduledId';

export const TimeSlotRescheduledDialog = () => {
  const scheduledTimeSlot = useSelector(scheduledTimeSlotLabelSelector);
  const { formatMessage } = useIntl();

  return (
    <Dialog
      confirmText={formatMessage(sharedCallsToAction.closeLabel)}
      contentContainerProps={{
        'data-e2e': 'dialogButtonContainer',
      }}
      dialogContainerProps={{
        'data-e2e': 'dialogContent',
      }}
      rootContainerProps={{
        'data-e2e': 'dialogContainer',
      }}
    >
      <Heading
        className="text-lg text-semibold mb3"
        id={TIME_SLOT_RESCHEDULED_DIALOG_ID}
        tagName="h2"
      >
        {formatMessage(messages.timeSlotRescheduledDialogHeading, {
          scheduledTimeSlot,
        })}
      </Heading>
      <p className="text-xxs">
        {formatMessage(messages.timeSlotRescheduledDialogBody)}
      </p>
    </Dialog>
  );
};
