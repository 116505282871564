import { createSelector } from 'reselect';

// TODO: WG-55555 Group Gifting 3rd party (entire module)
// TODO: WG-55555 BEGIN
// TODO: WG-55555 remove these selectors if/when all 3rd party restrictions are lifted
// TODO: WG-55555 This handles the Group Gifting restriction on 3rd party payments, selectors
// TODO: WG-55555 for temporary condition on "more" payment types (PayPal)
// TODO: WG-55555 Condition: remove gifting when PayPal for groups supported upstream

import { bottomSheetReturnUrlSelector } from 'shared/app/shell/state/selectors/app-ui';
import { routesSelector } from 'shared/app/state/selectors/routes';
import { isGroupGiftOrderSelector } from './group-gifting';

// Any disabling of More (3rd Party) Payments MUST emanate from a restricted bottomsheet
// ...either:  /menu/cart#payment-summary or /gift/00000271#payment
const PAYMENTS_PAGE_ROUTE = '/account/payment-method';
const GIFTING_BOTTOM_SHEET_ROUTE_PATTERN = /\/gift\/[0-9]{8}#payment/;

export const selectedOrderingPaymentsSelector = (state) => state?.ordering;

export const isPathThirdPartyPaymentsRestricted = (path, isGroupGifting) => {
  let restrictedGifting = false;
  const isGiftingRoute = GIFTING_BOTTOM_SHEET_ROUTE_PATTERN.test(path);
  if (isGiftingRoute) {
    if (isGroupGifting) {
      restrictedGifting = true;
    }
  }
  return restrictedGifting;
};

export const isCurrentRoutePaymentsPageSelector = createSelector(
  routesSelector,
  (routes) => routes.current.pathname === PAYMENTS_PAGE_ROUTE
);

export const isSourceRouteThirdPartyPaymentRestrictedSelector = createSelector(
  routesSelector,
  isGroupGiftOrderSelector,
  (routes, isGroupGifting) => {
    const path = `${routes.previous.pathname}${routes.previous.hash}`;
    return isPathThirdPartyPaymentsRestricted(path, isGroupGifting);
  }
);

export const shouldDisallowThirdPartyPaymentOptionSelector = createSelector(
  isCurrentRoutePaymentsPageSelector,
  isSourceRouteThirdPartyPaymentRestrictedSelector,
  (isCurrentRoutePaymentsPage, isSourceRouteRestricted) =>
    isCurrentRoutePaymentsPage && isSourceRouteRestricted
);

export const shouldEnableMorePaymentsSelector = createSelector(
  shouldDisallowThirdPartyPaymentOptionSelector,
  (disableMorePaymentsForAddingPayment) => !disableMorePaymentsForAddingPayment
);

export const shouldAllowBottomSheetRedirectSelector = createSelector(
  bottomSheetReturnUrlSelector,
  isGroupGiftOrderSelector,
  (returnUrl, isGroupGifting) => {
    const path = returnUrl;
    return !isPathThirdPartyPaymentsRestricted(path, isGroupGifting);
  }
);

// TODO: WG-55555 END temp
