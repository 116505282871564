/* eslint max-params: 0*/
import { createSelector } from 'reselect';
import { find } from 'lodash';

import {
  queryParamsSelector,
  routeParamsSelector,
} from 'shared/app/state/selectors/routes';
import {
  svcCardsDataSelector,
  primarySvcCardSelector,
} from 'shared/app/bundles/svc-cards';
import { activeScanToPayCardIdSelector } from './ui';

// identity selectors
export const barcodesSelector = (state) => state.accountCards.barcodes;

export const cardsWithNumbersSelector = createSelector(
  svcCardsDataSelector,
  (cardsData) => {
    if (!cardsData) {
      return null;
    }
    const filtered = cardsData.filter((card) => Boolean(card.cardNumber));
    if (!filtered.length) {
      return null;
    }
    return filtered;
  }
);

export const cardsDisplaySelector = createSelector(
  svcCardsDataSelector,
  (data) => data || []
);

export const cardCountSelector = createSelector(
  cardsDisplaySelector,
  (cards) => cards.length
);

export const activeScanToPayCardSelector = createSelector(
  svcCardsDataSelector,
  primarySvcCardSelector,
  activeScanToPayCardIdSelector,
  (cards, primaryCard, activeScanToPayCardId) => {
    if (!cards) {
      return null;
    }
    if (activeScanToPayCardId) {
      return find(cards, (card) => card.cardId === activeScanToPayCardId);
    }
    return primaryCard;
  }
);

export const selectedCardSelector = createSelector(
  svcCardsDataSelector,
  routeParamsSelector,
  (cards, routeParams) => {
    if (!cards) {
      return null;
    }
    return find(cards, (card) => card.cardId === routeParams.cardId) || null;
  }
);

export const selectedOrPrimaryCardSelector = createSelector(
  svcCardsDataSelector,
  routeParamsSelector,
  primarySvcCardSelector,
  (cards, routeParams, primaryCard) => {
    if (!cards) {
      return null;
    }
    return (
      find(cards, (card) => card.cardId === routeParams.cardId) ||
      primaryCard ||
      null
    );
  }
);

export const selectedOrPrimaryCardIdSelector = createSelector(
  selectedOrPrimaryCardSelector,
  (selectedCard) => selectedCard?.cardId
);

export const arrivedFromAccountCreateSelector = createSelector(
  queryParamsSelector,
  (queryParams) => {
    const modeValue = queryParams?.mode;
    return modeValue && modeValue === 'from_account_create';
  }
);

export const cardRegistrationErrorCodeSelector = createSelector(
  queryParamsSelector,
  (queryParams) => {
    return queryParams?.card_error;
  }
);

export const cashstarTokenSelector = createSelector(
  queryParamsSelector,
  (queryParams) => queryParams?.cashstarToken
);
