import { showErrorNotification } from 'shared/app/shell';
import runSequentially from 'shared/app/utils/run-sequentially';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';

import { storeDataSelector } from './selectors';

import {
  SUBMIT_PICKUP_FORM,
  SUBMIT_PICKUP_FORM_ERROR,
  SUBMIT_PICKUP_FORM_SUCCESS,
  REFRESH_PICKUP_STORE,
} from './actions';

import { CREATE_CUSTOMER_ARRIVAL } from '../../universal/gql-operation-ids';

import messages from '../components/messages';

export const submitPickupForm =
  ({ type, firstName, selectedStall, storeNumber, orderToken }) =>
  (dispatch, getState, { gqlFetch }) => {
    const mutation = {
      operationId: CREATE_CUSTOMER_ARRIVAL,
      variables: {
        customerArrivalInput: {
          pickUpOption: type,
          storeNumber,
          orderToken,
          displayName: firstName,
          stallNumber: type === 'outdoor' ? 'OP' : selectedStall,
        },
      },
      destinationType: API_PROXY_V1,
    };

    dispatch({ type: SUBMIT_PICKUP_FORM });
    return gqlFetch(mutation)
      .then(() => {
        dispatch({ type: SUBMIT_PICKUP_FORM_SUCCESS });
      })
      .catch((err) => {
        runSequentially(
          () => dispatch({ type: SUBMIT_PICKUP_FORM_ERROR }),
          () =>
            dispatch(
              showErrorNotification({
                messageId: messages.errorMessage.id,
                useErrorLoadingIndicator: true,
              })
            )
        );
        throw err;
      });
  };

export const verifyPickupStore = (storeNumber) => (dispatch, getState) => {
  const storeData = storeDataSelector(getState());

  if (storeData.storeNumber !== storeNumber) {
    dispatch({ type: REFRESH_PICKUP_STORE });
  }
};
