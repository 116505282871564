import {
  FETCH_EXPIRATIONS,
  FETCH_EXPIRATIONS_SUCCESS,
  FETCH_EXPIRATIONS_ERROR,
} from '../../actions';
import { API_PROXY_V1 } from 'shared/app/utils/create-gql-fetcher';
import { GET_EXPIRING_STARS } from '../../../universal/gql-operation-ids';

export const fetchExpirations =
  () =>
  (dispatch, getState, { gqlFetch }) => {
    dispatch({ type: FETCH_EXPIRATIONS });

    return gqlFetch({
      operationId: GET_EXPIRING_STARS,
      destinationType: API_PROXY_V1,
    })
      .then((payload) => {
        if (payload.errors) {
          throw new Error(payload.errors[0].message);
        }
        dispatch({
          type: FETCH_EXPIRATIONS_SUCCESS,
          payload: payload.user.loyaltyProgram.expiringStars,
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_EXPIRATIONS_ERROR,
        });
      });
  };
