/* eslint-disable camelcase */
import { trackEvent, trackPageView } from 'shared/app/utils/uo-tracking';
import { SUCCESS_PURCHASE_STATUS } from 'shared/app/components/gift-receipt/constants';

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGiftLandingView = () => trackPageView('GIFT_LANDING_VIEW');

/**
 * @param {{gift_heirarchy_name: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGiftListView = (eventAttributes) =>
  trackPageView('GIFT_LIST_VIEW', eventAttributes);

/**
 * @param {{gift_card_id: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackAddCardClick = (eventAttributes) =>
  trackEvent('GIFT_ADD_CARD_CLICK', eventAttributes);

/**
 * @param {{gift_card_id: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGiftCartView = (eventAttributes) =>
  trackPageView('GIFT_CART_VIEW', eventAttributes);

/**
 * @param {{gift_card_id: string}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGiftCartCheckoutClick = (eventAttributes) =>
  trackEvent('GIFT_CART_CHECKOUT_CLICK', eventAttributes);

/**
 * @param {{gift_card_id: string, gift_card_recipient_count: number}} eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGiftCardOrderSuccess = (eventAttributes) =>
  trackEvent('GIFT_CARD_ORDER_SUCCESS', eventAttributes);

/**
 * @param {string} egiftPurchaseStatus - Possible values: success or pending
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGiftCardPostOrderView = (egiftPurchaseStatus) => {
  return trackEvent('GIFT_CARD_POST_ORDER_VIEW', {
    'gift_card_status':
      egiftPurchaseStatus === SUCCESS_PURCHASE_STATUS
        ? SUCCESS_PURCHASE_STATUS
        : 'processing',
  });
};
