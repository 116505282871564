/* eslint-disable max-params */

import { createSelector } from 'reselect';
import ms from 'milliseconds';

import {
  signedInSelector,
  hasGuestSessionSelector,
} from 'shared/app/bundles/user';
import { appTimeSelector } from 'shared/app/shell';
import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import shouldUpdate from 'shared/app/stale-reducers/should-update';
import { getShortStoreNumber } from 'shared/app/bundles/menu/util/get-filter-query';

const orderingSelector = (state) => state.ordering;

export const pricingByStoreSelector = createSelector(
  orderingSelector,
  (ordering) => ordering?.pricingByStore
);

const pricingByStoreCacheTime = ms.minutes(5);

export const shouldFetchPricingByStoreSelector = createSelector(
  signedInSelector,
  hasGuestSessionSelector,
  selectedStoreSelector,
  pricingByStoreSelector,
  appTimeSelector,
  (signedIn, hasGuestSession, selectedStore, pricingByStore, appTime) => {
    const storeNumber = getShortStoreNumber(selectedStore?.store?.storeNumber);

    if (!storeNumber || (!signedIn && !hasGuestSession)) return false;

    const storeNumberChanged = storeNumber !== pricingByStore?.storeNumber;

    const updateNeeded = shouldUpdate(pricingByStore, {
      staleTime: pricingByStoreCacheTime,
      now: appTime,
    });

    return storeNumberChanged || updateNeeded;
  }
);
