import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addFavoriteProduct: {
    id: 'ordering.notifications.addFavoriteProduct',
    defaultMessage: '{name} added to favorites',
    description: 'Message displayed when a user adds a new favorite product',
  },
  removeFavoriteProduct: {
    id: 'ordering.notifications.removeFavoriteProduct',
    defaultMessage: '{name} removed from favorites',
    description: 'Message displayed when a user removes a favorite product',
  },
  favoriteMaxLimit: {
    id: 'ordering.notifications.favoriteMaxLimit',
    defaultMessage:
      "Sorry, you've reached the maximum number of favorite items",
    description:
      'Message displayed when a user tries to add a favorite product but has reached the maximum limit',
  },
});
