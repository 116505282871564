import {
  SET_URL_STATE,
  COLLAPSE_EXPANDED_STORE,
  SET_PLACE_NAME,
  FETCH_AT_PLACENAME,
  FETCH_LOCATIONS_SUCCESS,
  UPDATE_LOCATIONS_NEAR_STORE,
  SELECT_LOCATION,
  MOVE_MAP,
  SET_FEATURES,
  CLEAR_SELECTED_FEATURES,
} from '../actions/types';
import { GEOLOCATION_SUCCESS } from 'shared/app/bundles/geolocation';

// Check current locations for expanded store,
// return store object if it exists in the current array.
const getStoreData = ({ locations, expandedStoreNumber }) => {
  let found = {};
  // we use `some` here in order to be able to stop iterating
  // once found.
  locations.some((location) => {
    if (location.store.storeNumber === expandedStoreNumber) {
      found = location;
      return true;
    }
    return false;
  });

  return found;
};

/* eslint-disable complexity */
const expandedStore = (state = null, action) => {
  switch (action.type) {
    case SET_URL_STATE:
      const { expandedStoreNumber } = action.payload;

      return expandedStoreNumber
        ? Object.assign({}, state, {
            storeNumber: expandedStoreNumber,
            expanded: true,
          })
        : state;

    case FETCH_LOCATIONS_SUCCESS:
      // Update expanded store data when locations change.
      return !state
        ? null
        : Object.assign(
            {},
            state,
            getStoreData({
              locations: action.payload.stores,
              expandedStoreNumber: state.storeNumber,
            })
          );

    case UPDATE_LOCATIONS_NEAR_STORE:
      return Object.assign(
        {},
        state,
        getStoreData({
          locations: action.payload.stores,
          expandedStoreNumber: state.storeNumber,
        })
      );
    case SELECT_LOCATION: {
      const { location, expanded } = action.payload;
      return { ...location, expanded };
    }
    case COLLAPSE_EXPANDED_STORE:
      return { ...state, expanded: false };
    case GEOLOCATION_SUCCESS:
      return action.payload.userInitiated ? null : state;
    case SET_PLACE_NAME:
      return null;
    case FETCH_AT_PLACENAME:
      return null;
    case SET_FEATURES:
      return null;
    case CLEAR_SELECTED_FEATURES:
      return null;
    case MOVE_MAP:
      // If there are no store details, clear expanded state on map movement.
      return state && state.name ? state : null;
    default:
      return state;
  }
};
/* eslint-enable complexity */

export default expandedStore;
