import { createSelector } from 'reselect';
import { get, includes } from 'lodash';

import formatUrl from '../../../../shared/universal/format-url';
import { isCoreAppUrlSelector } from 'shared/app/state/selectors/locales';
import { pathnameSelector } from 'shared/app/state/selectors/routes';

export const configSelector = (state) => state.config;

const featuresStateSelector = createSelector(
  configSelector,
  (config) => config.features
);

export const inMenuStoreLocatorSelector = createSelector(
  configSelector,
  pathnameSelector,
  (config, pathname) => includes(pathname, config.orderingUrlRoot)
);

export const inStandaloneStoreLocatorSelector = createSelector(
  inMenuStoreLocatorSelector,
  isCoreAppUrlSelector,
  (inMenuStoreLocator, isCoreAppUrl) => !inMenuStoreLocator && !isCoreAppUrl
);

const isFilterable = (feature) => feature.filterable;

export const filterableFeaturesSelector = createSelector(
  featuresStateSelector,
  (featuresState) => featuresState.filter(isFilterable)
);

export const publicFeaturesSelector = createSelector(
  featuresStateSelector,
  (featuresState) => featuresState.filter((feature) => feature.public)
);

export const privateFeaturesSelector = createSelector(
  featuresStateSelector,
  (featuresState) => featuresState.filter((feature) => feature.public === false)
);

export const mapDefaultsSelector = createSelector(configSelector, (config) => ({
  lat: parseFloat(config.mapDefaults.lat),
  lng: parseFloat(config.mapDefaults.lng),
  zoom: config.mapDefaults.zoom,
}));

export const googleMapsUrlSelector = createSelector(
  configSelector,
  (config) => config.googleMapsUrl
);

export const configImagesUrlSelector = createSelector(
  configSelector,
  (config) => {
    const { host, path } = get(config, 'universal.staticUrls.images', {});

    return formatUrl(host, path);
  }
);

export const orderAndPickupOptionsSelector = createSelector(
  configSelector,
  featuresStateSelector,
  (config, featuresState) =>
    featuresState.filter(
      (feature) =>
        feature?.category === config.PICKUP_OPTIONS_CATEGORY_NAME &&
        feature?.code !== 'XO'
    )
);

export const doordashStorefrontUrlSelector = createSelector(
  configSelector,
  (config) => config.doordashStorefrontUrl
);

export const doordashFaqUrlSelector = createSelector(
  configSelector,
  (config) => config.doordashFaqUrl
);
