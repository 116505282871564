import createActionTypes from 'shared/app/state/create-action-types';

export const CLEAR_ORDER_PRICING = 'CLEAR_ORDER_PRICING';

export const CLEAR_CURRENT_CART = 'CLEAR_CURRENT_CART';
export const CLEAR_CART_AVAILABLE_REWARDS = 'CLEAR_CART_AVAILABLE_REWARDS';
export const SET_APPLY_CART_OFFER = 'SET_APPLY_CART_OFFER';

export const SELECT_STORE_MENU = 'SELECT_STORE_MENU';
export const CLEAR_STORE_MENU = 'CLEAR_STORE_MENU';

export const SELECT_PICKUP_OPTION = 'SELECT_PICKUP_OPTION';

export const SET_LOCATION_BY_NUMBER = 'SET_LOCATION_BY_NUMBER';

export const CLEAR_ORDER_STATUS = 'CLEAR_ORDER_STATUS';

export const ATTACH_LAST_ORDER = 'ATTACH_LAST_ORDER';

export const SET_ORDER_METHOD = 'SET_ORDER_METHOD';

export const GUEST_EMAIL_MARKETING_OPT_IN = 'GUEST_EMAIL_MARKETING_OPT_IN';

export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';
export const AVAILABILITY_CORRECT = 'AVAILABILITY_CORRECT';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_OOS_RECOMMENDATION_TO_CART = 'ADD_OOS_RECOMMENDATION_TO_CART';
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';

export const CART_SYNC_NO_CART = 'CART_SYNC_NO_CART';
export const CART_SYNC_SUCCESS = 'CART_SYNC_SUCCESS';
export const CART_SYNC_FAILURE = 'CART_SYNC_FAILURE';

export const HIDE_ADDED_PRODUCT_NOTIFICATION =
  'HIDE_ADDED_PRODUCT_NOTIFICATION';

export const UPDATE_ORDER_DELIVERY_TYPE = 'UPDATE_ORDER_DELIVERY_TYPE';
export const REWARD_REDEEMED = 'REWARD_REDEEMED';

export const INVALIDATE_MOST_RECENT_ORDER = 'INVALIDATE_MOST_RECENT_ORDER';

export const SET_PICKUP_LOCATION = 'SET_PICKUP_LOCATION';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const NOOP = 'NOOP';

export const PRE_ORDER_PICKUP_ESTIMATE = 'PRE_ORDER_PICKUP_ESTIMATE';

export const GUEST_ORDER_TOTAL_AMOUNT = 'GUEST_ORDER_TOTAL_AMOUNT';

export const SET_POST_STORE_SELECTION_RETURN_URL =
  'SET_POST_STORE_SELECTION_RETURN_URL';

export const SET_SCHEDULED_ORDERING_TIME_SLOT =
  'SET_SCHEDULED_ORDERING_TIME_SLOT';

export const SET_SCHEDULED_ORDERING_MOCK_STATE =
  'SET_SCHEDULED_ORDERING_MOCK_STATE';

export const {
  SUBMIT_ORDER,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_ERROR,

  SUBMIT_ORDER_GUEST,
  SUBMIT_ORDER_GUEST_SUCCESS,
  SUBMIT_ORDER_GUEST_ERROR,

  FETCH_ORDER_PRICING,
  FETCH_ORDER_PRICING_SUCCESS,
  FETCH_ORDER_PRICING_ERROR,

  FETCH_FAVORITE_PRODUCTS,
  FETCH_FAVORITE_PRODUCTS_SUCCESS,
  FETCH_FAVORITE_PRODUCTS_ERROR,

  SAVE_FAVORITE_PRODUCT,
  SAVE_FAVORITE_PRODUCT_SUCCESS,
  SAVE_FAVORITE_PRODUCT_ERROR,

  REMOVE_FAVORITE_PRODUCT,
  REMOVE_FAVORITE_PRODUCT_SUCCESS,
  REMOVE_FAVORITE_PRODUCT_ERROR,

  FETCH_PREVIOUS_ORDERS,
  FETCH_PREVIOUS_ORDERS_SUCCESS,
  FETCH_PREVIOUS_ORDERS_ERROR,

  FETCH_PRODUCT_DETAILS,
  FETCH_PRODUCT_DETAILS_SUCCESS,
  FETCH_PRODUCT_DETAILS_ERROR,

  FETCH_SIMILAR_ITEMS,
  FETCH_SIMILAR_ITEMS_SUCCESS,
  FETCH_SIMILAR_ITEMS_ERROR,

  GET_PRE_ORDER_PICKUP_ESTIMATE,
  GET_PRE_ORDER_PICKUP_ESTIMATE_SUCCESS,
  GET_PRE_ORDER_PICKUP_ESTIMATE_ERROR,

  GET_ORDER_PICKUP_TIME,
  GET_ORDER_PICKUP_TIME_SUCCESS,
  GET_ORDER_PICKUP_TIME_ERROR,

  FETCH_STORE_TIME_SLOTS,
  FETCH_STORE_TIME_SLOTS_SUCCESS,
  FETCH_STORE_TIME_SLOTS_ERROR,

  FETCH_PRICING_RULES,
  FETCH_PRICING_RULES_SUCCESS,
  FETCH_PRICING_RULES_ERROR,

  FETCH_PRICING_BY_STORE,
  FETCH_PRICING_BY_STORE_SUCCESS,
  FETCH_PRICING_BY_STORE_ERROR,
} = createActionTypes([
  'SUBMIT_ORDER',
  'SUBMIT_ORDER_GUEST',
  'FETCH_ORDER_PRICING',
  'FETCH_FAVORITE_PRODUCTS',
  'SAVE_FAVORITE_PRODUCT',
  'REMOVE_FAVORITE_PRODUCT',
  'FETCH_PREVIOUS_ORDERS',
  'FETCH_PRODUCT_DETAILS',
  'FETCH_SIMILAR_ITEMS',
  'GET_PRE_ORDER_PICKUP_ESTIMATE',
  'GET_ORDER_PICKUP_TIME',
  'FETCH_STORE_TIME_SLOTS',
  'FETCH_PRICING_RULES',
  'FETCH_PRICING_BY_STORE',
]);
