import { createSelector } from 'reselect';

import { signedInSelector } from 'shared/app/bundles/user';
import { configSelector } from 'shared/app/shell/state/selectors/config';
import { appTimeSelector } from 'shared/app/shell';
import {
  ADD_PAYMENT,
  paymentInstrumentsSelector,
} from 'shared/app/bundles/wallet';
import { isGroupGiftSelector } from './gift-form';

const validGiftPaymentInstrumentsList = ['paypal'];

const validGroupGiftPaymentInstrumentsList = [
  'amex',
  'discover',
  'mastercard',
  'visa',
];

export const selectedPaymentInstrumentIdSelector = (state) =>
  state.gift.selectedPaymentInstrumentId;

export const validGiftPaymentInstrumentsSelector = createSelector(
  paymentInstrumentsSelector,
  isGroupGiftSelector,
  (paymentInstruments, isGroupGift) => {
    // API currently can't handle multiple gifts purchased with PayPal
    const giftInstrumentAllowList = isGroupGift
      ? validGroupGiftPaymentInstrumentsList
      : [
          ...validGroupGiftPaymentInstrumentsList,
          ...validGiftPaymentInstrumentsList,
        ];
    // only return payment instrument types from known list
    return paymentInstruments.filter((instrument) =>
      giftInstrumentAllowList.includes(instrument?.paymentType?.toLowerCase())
    );
  }
);

export const guestPaymentInstrumentSelector = (state) =>
  state.gift?.guestPaymentInstrument ?? null;

export const hasGuestPaymentInstrumentSelector = createSelector(
  guestPaymentInstrumentSelector,
  (guestPaymentInstrument) => Boolean(guestPaymentInstrument)
);

export const guestPaymentInstrumentsSelector = createSelector(
  guestPaymentInstrumentSelector,
  (guestPaymentInstrument) => {
    return guestPaymentInstrument
      ? [
          {
            accountNumberLastFour: guestPaymentInstrument.accountNumberLastFour,
            default: true,
            paymentType: guestPaymentInstrument.paymentType,
            paymentInstrumentId: 'guest',
          },
        ]
      : [];
  }
);

/* eslint-disable max-params */
export const allGiftPaymentInstrumentsSelector = createSelector(
  guestPaymentInstrumentsSelector,
  validGiftPaymentInstrumentsSelector,
  signedInSelector,
  (guestPaymentInstruments, validPaymentInstruments, signedIn) => {
    const addPaymentInstrument = {
      paymentType: ADD_PAYMENT,
      paymentMethodId: ADD_PAYMENT,
      paymentInstrumentId: ADD_PAYMENT,
    };

    const paymentInstrumentList = signedIn
      ? validPaymentInstruments
      : guestPaymentInstruments;

    return [...paymentInstrumentList, addPaymentInstrument].filter(Boolean);
  }
);
/* eslint-enable max-params */

export const giftPaymentInstrumentsSelector = createSelector(
  guestPaymentInstrumentsSelector,
  paymentInstrumentsSelector,
  signedInSelector,
  (guestPaymentInstruments, paymentInstruments, signedIn) => {
    return signedIn ? paymentInstruments : guestPaymentInstruments;
  }
);

export const defaultOrFirstGiftPaymentInstrumentSelector = createSelector(
  guestPaymentInstrumentsSelector,
  validGiftPaymentInstrumentsSelector,
  signedInSelector,
  (guestPaymentInstruments, paymentInstruments, signedIn) => {
    const paymentInstrumentsList = signedIn
      ? paymentInstruments
      : guestPaymentInstruments;
    const defaultPayment = paymentInstrumentsList.find((pi) => pi.default);

    if (defaultPayment) {
      return defaultPayment;
    }

    return paymentInstrumentsList?.[0] ?? {};
  }
);

export const securePaymentSelector = createSelector(
  configSelector,
  (config) => config?.universal?.securePayment
);

export const guestPaymentTokenMaxAgeSelecter = createSelector(
  securePaymentSelector,
  (securePayment) => securePayment?.guestPaymentTokenMaxAge ?? 300000
);

export const guestPaymentTokenAcquiredTimestampSelecter = createSelector(
  guestPaymentInstrumentSelector,
  (paymentInstrument) => paymentInstrument?.tokenAcquiredTimestamp ?? 0
);

export const guestPaymentInstrumentTokenIsExpiredSelector = createSelector(
  appTimeSelector,
  guestPaymentTokenMaxAgeSelecter,
  guestPaymentTokenAcquiredTimestampSelecter,
  (time, maxTokenAge, tokenAcquiredTimestamp) => {
    if (tokenAcquiredTimestamp <= 0) {
      // ATTN: (authN gift depends on this!)
      // If no single-use credit card is "on file", this is a
      // NO-OP and should assume the payment instrument is usable
      return false; // NOT_EXPIRED
    }
    const elapsedTime = time - tokenAcquiredTimestamp;

    return elapsedTime > maxTokenAge;
  }
);

export const selectedGiftPaymentInstrumentIdSelector = createSelector(
  selectedPaymentInstrumentIdSelector,
  defaultOrFirstGiftPaymentInstrumentSelector,
  (selectedPaymentInstrumentId, defaultPaymentInstrument) =>
    selectedPaymentInstrumentId ||
    defaultPaymentInstrument?.paymentInstrumentId ||
    ADD_PAYMENT
);
