import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import geolocatePath from '@starbucks-web/pattern-library/lib/icons/geolocate';
import Frap from 'shared/app/components/frap-with-messages';

import {
  geolocationFailedSelector,
  geolocationIsLoadingSelector,
  requestGeolocation,
} from 'shared/app/bundles/geolocation';
import { userGeolocationSelector } from '../../state/selectors';

import messages from './messages';
import styles from './styles.cssm';

const GeolocateButton = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const geolocationError = useSelector(geolocationFailedSelector) || false;
  const geolocationInProgress = useSelector(geolocationIsLoadingSelector);
  const userGeolocation = useSelector(userGeolocationSelector);

  const handleClick = () => {
    dispatch(requestGeolocation({ userInitiated: true }));
  };

  const className = classNames(styles.base, {
    [styles.inProgress]: geolocationInProgress,
    [styles.isActive]: userGeolocation && !geolocationInProgress,
    'hidden': geolocationError,
  });

  return (
    <Frap
      aria-label={formatMessage(messages.label)}
      circular
      className={className}
      data-e2e="geolocateButton"
      disabled={geolocationError}
      mini
      onClick={handleClick}
    >
      <Icon
        className={`block mx-auto ${styles.icon}`}
        path={geolocatePath}
        size="26px"
      />
    </Frap>
  );
};

export default GeolocateButton;
