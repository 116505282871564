import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import checkSolid from '@starbucks-web/pattern-library/lib/icons/check-solid';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import Rule from '@starbucks-web/pattern-library/lib/components/rule';

import LegacyPaddedContentColumn from 'shared/app/components/legacy-padded-content-column';

import PickupSurvey from './pickup-survey';

import {
  surveyAvailableSelector,
  surveyInitCodeSelector,
} from '../state/selectors';

import messages from './messages';

const Success = ({ surveyAvailable, surveyInitCode }) => {
  return (
    <div className="bg-white height-100">
      <LegacyPaddedContentColumn
        className="text-center flex flex-column items-center height-100"
        size="md"
      >
        <Icon className="color-greenAccent mt5" path={checkSolid} size="52px" />
        <Heading className="py2" size="lg" tagName="h2">
          <FormattedMessage {...messages.successHeading} />
        </Heading>
        <p className="pt2 px4" style={{ fontSize: '17px' }}>
          <FormattedMessage {...messages.successMessage} />
        </p>
        <div className="size12of12" style={{ marginTop: 'auto' }}>
          {surveyAvailable && (
            <Fragment>
              <Rule className="pb3" weight="thin" />
              <PickupSurvey surveyInitCode={surveyInitCode} />
            </Fragment>
          )}
        </div>
      </LegacyPaddedContentColumn>
    </div>
  );
};

const select = (state) => ({
  surveyAvailable: surveyAvailableSelector(state),
  surveyInitCode: surveyInitCodeSelector(state),
});

export default connect(select)(Success);
