import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectLocation } from '../../state/actions/location';
import GeolocationMarker from '../geolocation-marker';
import ZoomControls from '../locator-map-zoom-controls';
import GoogleMap from './google-map';
import LocatorMarker from '../locator-marker';
import GeolocateButton from '../geolocate-button';
import {
  hideLocationsSelector,
  highlightedStoreNumberSelector,
  inMenuStoreLocatorSelector,
  filteredLocationsSelector,
  mapInstanceSelector,
  selectedStoreNumberSelector,
} from '../../state/selectors';

import { requestGeolocation } from 'shared/app/bundles/geolocation';
import { addNotification } from 'shared/app/shell/state/action-creators/notifications';

import styles from './styles.cssm';
import messages from './messages';

export const LocatorMap = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [selectedStoreName, setSelectedStoreName] = useState('');
  useEffect(() => {
    dispatch(requestGeolocation());
  }, []);

  const inMenuStoreLocator = useSelector(inMenuStoreLocatorSelector);
  const filteredLocations = useSelector(filteredLocationsSelector);
  const hideLocations = useSelector(hideLocationsSelector) || false;
  const mapInstance = useSelector(mapInstanceSelector);
  const selectedStoreNumber = useSelector(selectedStoreNumberSelector);
  const highlightedStoreNumber = useSelector(highlightedStoreNumberSelector);

  const selectStoreLocation = (locationNumber) => {
    const location = filteredLocations.find(
      (storeLocation) => storeLocation.store.storeNumber === locationNumber
    );

    if (!location) {
      return;
    }

    if (
      inMenuStoreLocator &&
      location.store.mobileOrdering.availability !== 'READY'
    ) {
      // We are adding a setTimeout 1 of 0 which adds the animation to the end of the rendering queue.
      // This is needed due to the way Google Maps handles touch events
      setTimeout(() => {
        dispatch(addNotification(formatMessage(messages.menuNotAvailable)));
      }, 0);
      return;
    }
    setSelectedStoreName(location?.store?.name);

    dispatch(
      selectLocation(locationNumber, {
        location,
        userOverride: false,
        preventMovement: true,
        shouldScroll: true,
      })
    );
  };

  const renderMarkers = () => {
    if (!mapInstance || hideLocations) {
      return null;
    }

    return filteredLocations.map(({ store }) => {
      return (
        <LocatorMarker
          grayedOut={
            inMenuStoreLocator && store.mobileOrdering.availability !== 'READY'
          }
          highlighted={highlightedStoreNumber === store.storeNumber}
          key={store.storeNumber}
          locationNumber={store.storeNumber}
          mapInstance={mapInstance}
          position={store.coordinates}
          selectLocation={selectStoreLocation}
          selected={selectedStoreNumber === store.storeNumber}
          storeName={store.name}
        />
      );
    });
  };

  return (
    <div className={styles.container}>
      <GoogleMap />
      <div className={`mx2 md-mx0 text-center ${styles.buttonPosition}`}>
        <ZoomControls />
        <GeolocateButton />
      </div>
      <div aria-atomic="true" aria-live="polite" className="hiddenVisually">
        {selectedStoreName}
      </div>
      <GeolocationMarker />
      {renderMarkers()}
    </div>
  );
};

export default LocatorMap;
