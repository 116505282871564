import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import tipJar from '@starbucks-web/pattern-library/lib/icons/tip-jar';
import TipAmountSelect from 'shared/app/components/tip-amount-select';

const TipAmountContainer = ({ tipAmount, setTipAmount }) => (
  <div className="flex justify-between items-center">
    <Icon className="color-greenStarbucks mx3" path={tipJar} />
    <TipAmountSelect
      data-e2e="tip-amount-selector"
      setTipAmount={setTipAmount}
      tipAmount={tipAmount}
    />
  </div>
);

TipAmountContainer.propTypes = {
  tipAmount: PropTypes.string,
  setTipAmount: PropTypes.func,
};

export default TipAmountContainer;
