import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';
import { localeTagSelector } from 'shared/app/state/selectors/locales';
import {
  getUrlWithQuery,
  getStoreNumberQuery,
} from 'shared/app/bundles/menu/util/get-filter-query';

import { generateProductOptionsMap } from 'ordering/app/util/generate-product-options-map';
import {
  FETCH_PRODUCT_DETAILS,
  FETCH_PRODUCT_DETAILS_SUCCESS,
  FETCH_PRODUCT_DETAILS_ERROR,
} from './types';

export const AGGREGATOR_ID_PROP = 'formId';

export const fetchProductDetails =
  (formId) =>
  (dispatch, getState, { apiFetch }) => {
    const state = getState();
    const storeNumber =
      selectedStoreSelector(state)?.store?.storeNumber ?? null;
    const locale = localeTagSelector(state);
    const storeNumberQuery = getStoreNumberQuery(storeNumber);

    const baseProductPath = `/apiproxy/v1/ordering/${formId}`;

    const options = {
      headers: {
        'Accept-Language': locale,
      },
    };

    dispatch({
      type: FETCH_PRODUCT_DETAILS,
      payload: formId,
    });

    const url = getUrlWithQuery(baseProductPath, storeNumberQuery);

    return apiFetch(url, options)
      .then((data) => {
        const payload = data?.products?.[0];
        // append the formId as the 'idProp' to use in createAggregatorReducer
        // since the payload does not have a unique identifier
        payload[AGGREGATOR_ID_PROP] = formId;
        // generate a map of productOptions by option productNumber
        // to ease the pain of matching PDP selectedOptions, cart options,
        // and PDP recipe options
        payload.optionsByProductNumber = generateProductOptionsMap(payload);
        dispatch({
          type: FETCH_PRODUCT_DETAILS_SUCCESS,
          payload,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_PRODUCT_DETAILS_ERROR,
          // payload for an aggregator reducer is the `idProp` of the object
          // to be updated, so that the error is assigned to the correct object
          // in the aggregated collection.
          payload: formId,
          error: err,
        });
      });
  };
