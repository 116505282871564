import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import HeaderCrate from '@starbucks-web/pattern-library/lib/components/header-crate';

import H1 from 'shared/app/components/h1';
import { firstNameSelector, signedInSelector } from 'shared/app/bundles/user';

import sharedMessages from '../messages';

import { getGreeting } from './util';

const Greeting = () => {
  const { formatMessage } = useIntl();
  const firstName = useSelector(firstNameSelector);
  const isSignedIn = useSelector(signedInSelector);

  const greeting = getGreeting();
  const { message, emoji } = greeting;
  return (
    <HeaderCrate.Inner>
      <Helmet title={formatMessage(sharedMessages.streamTitle)} />
      <H1 className="text-bold" data-e2e="welcome-message" size="lg">
        <FormattedMessage
          {...message}
          values={{
            firstName,
            hasName: Boolean(firstName),
            isSignedIn,
          }}
        />
        &nbsp;
        {emoji}
      </H1>
    </HeaderCrate.Inner>
  );
};

Greeting.propTypes = {
  firstName: PropTypes.string,
  locale: PropTypes.string,
};

export default Greeting;
