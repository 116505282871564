import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectedStoreSelector } from 'shared/app/state/selectors/ordering';

import messages from './messages';

const CaliTaxLabel = () => {
  const { formatMessage } = useIntl();
  const selectedStore = useSelector(selectedStoreSelector);
  const isCaliforniaStore =
    selectedStore?.store?.address?.countrySubdivisionCode === 'CA';

  return isCaliforniaStore ? (
    <p className="pt3 color-textBlackSoft text-xxs">
      {formatMessage(messages.caTaxLabel)}
    </p>
  ) : null;
};

export default CaliTaxLabel;
