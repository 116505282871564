import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import caretDownPath from '@starbucks-web/pattern-library/lib/icons/caret-down';
import Expander from '@starbucks-web/pattern-library/lib/components/expander';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import Coupons from 'shared/app/components/coupons';

import RewardsLevelsDescriptions from 'shared/app/components/rewards-levels-descriptions';

import messages from './messages';
import styles from './index.cssm';

const RewardsLevelsContainer = ({ intl: { formatMessage } }) => {
  const [isExpanded, toggleExpander] = useState(false);

  return (
    <div
      className="p2 md-p3 cursor-pointer"
      onClick={() => {
        toggleExpander(!isExpanded);
      }}
    >
      <Heading
        className="flex justify-between items-center pl2 md-pl0"
        tagName="h2"
      >
        <div className="text-bold text-sm">
          <FormattedMessage {...messages.heading} />
        </div>
        <Button
          aria-controls="RewardStarLevelDescriptions"
          aria-expanded={isExpanded}
          aria-label={formatMessage(messages.expandButton)}
          className="p0 ml2"
          visualStyle="textOnly"
        >
          <Icon
            className={`color-black ${styles.caret} ${
              isExpanded && styles.expanded
            }`}
            path={caretDownPath}
            size="38px"
          />
        </Button>
      </Heading>
      <Expander
        expandEffect="pushdown"
        id="RewardStarLevelDescriptions"
        isExpanded={isExpanded}
      >
        <RewardsLevelsDescriptions />
        <Coupons
          cardProps={{ disableBoxShadow: true }}
          className={`bg-ceramic mt3 ${styles.borderRadius}`}
        />
      </Expander>
    </div>
  );
};

export default injectIntl(RewardsLevelsContainer);
