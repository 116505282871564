import { defineMessages } from 'react-intl';

export default defineMessages({
  choosePaymentOption: {
    id: 'ordering.orderPaymentBottomSheet.choosePaymentOption',
    defaultMessage: 'Choose payment',
    description: 'Heading for the payment summary bottom sheet',
  },
  insufficientFundsNotification: {
    id: 'ordering.orderPaymentBottomSheet.insufficientFundsNotification',
    defaultMessage:
      'Looks like your card balance is too low to complete your order.',
    description:
      'Toast indicating that the selected card has insufficient funds',
  },
  insufficientFunds: {
    id: 'ordering.orderPaymentBottomSheet.insufficientFunds',
    defaultMessage: 'Insufficient balance for order',
    description:
      'Message indicating that the user cannot purchase this order because their card has insufficient funds.',
  },
  noPaymentMethods: {
    id: 'ordering.orderPaymentBottomSheet.noPaymentMethods',
    defaultMessage: 'Your account has no payment methods.',
    description:
      'Tells the user that their account contains no payment methods.',
  },
  selectLoadAmount: {
    id: 'ordering.orderPaymentBottomSheet.selectLoadAmount',
    defaultMessage: 'Amount',
    description: 'Label for dropdown with available reload amounts',
  },
  submitOrder: {
    id: 'ordering.orderPaymentBottomSheet.submitOrder',
    defaultMessage: 'Place order',
    description: 'Button label for submitting the order',
  },
  submitLoadAndOrder: {
    id: 'ordering.orderPaymentBottomSheet.submitLoadAndOrder',
    defaultMessage: 'Reload & place order',
    description: 'Button label for reloading a card and submitting the order',
  },
  svcPaymentOnly: {
    id: 'ordering.orderPaymentBottomSheet.svcPaymentOnly',
    defaultMessage:
      'This store only accepts Starbucks Cards as payment to order ahead',
    description: 'Notice that only SVC is allowed at this selected store',
  },
  pricingRefresh: {
    id: 'ordering.orderPaymentBottomSheet.notification.pricingRefresh',
    defaultMessage:
      'Looks like your order may be a little stale. Refresh to continue with your order.',
    description:
      'Message indicating that pricing/service time needs to be fetched',
  },
  confirmRefreshCTA: {
    id: 'ordering.orderPaymentBottomSheet.notification.confirmRefreshCTA',
    defaultMessage: 'Refresh',
    description: 'Button label for pricing refresh notification',
  },
  tipRemovedDialogHeading: {
    id: 'ordering.orderPaymentBottomSheet.notification.tipRemovedDialogHeading',
    defaultMessage: 'Your tip has been removed',
    description: 'Heading for tip removed dialog',
  },
  tipRemovedDialogBody: {
    id: 'ordering.orderPaymentBottomSheet.notification.tipRemovedDialogBody',
    defaultMessage: 'Tipping is not yet available for this payment type',
    description: 'Body for tip removed dialog',
  },
  scheduledOrderingReminder: {
    id: 'ordering.orderPaymentBottomSheet.scheduledOrderingReminder',
    defaultMessage:
      '{pickupMethod} scheduled for {timeSlot} at {locationSelected}',
    description: 'Body for scheduled ordering time slot reminder',
  },
});
