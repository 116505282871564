import {
  GET_ORDER_PICKUP_TIME,
  GET_ORDER_PICKUP_TIME_SUCCESS,
  GET_ORDER_PICKUP_TIME_ERROR,
  PRE_ORDER_PICKUP_ESTIMATE,
} from '../actions/types';

import { PICKUP_OVERLAY_SEEN } from 'shared/app/state/action-creators/types';

export default (state = {}, action) => {
  const orderTime = action?.payload?.orderTime;
  switch (action.type) {
    case PRE_ORDER_PICKUP_ESTIMATE: {
      const preOrderEstimates = action.payload ?? {};
      return {
        ...state,
        preOrderEstimates,
        pickupOverlaySeen: false,
      };
    }

    case GET_ORDER_PICKUP_TIME:
      return {
        ...state,
        orderTime,
        loading: true,
      };

    case GET_ORDER_PICKUP_TIME_SUCCESS: {
      const { locationId: shortStoreNumber, ...rest } = action.payload;
      return {
        ...state,
        shortStoreNumber,
        orderTime,
        ...rest,
        loading: false,
      };
    }

    case GET_ORDER_PICKUP_TIME_ERROR:
      return {
        preOrderEstimates: state.preOrderEstimates,
        orderTime,
        loading: false,
      };

    case PICKUP_OVERLAY_SEEN:
      return {
        ...state,
        pickupOverlaySeen: true,
      };
  }
  return state;
};
