import {
  BUY_DIGITAL_GIFT_SUCCESS,
  BUY_DIGITAL_GIFT_ERROR,
  UPDATE_GIFT_PURCHASE_STATUS,
} from '../../actions';

const defaultState = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case BUY_DIGITAL_GIFT_SUCCESS:
    case BUY_DIGITAL_GIFT_ERROR:
      return action.payload;
    case UPDATE_GIFT_PURCHASE_STATUS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
